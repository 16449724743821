<template>
  <HeaderNav title="HOME" ref="HeaderNav" />
  <section class="home">
    <div class="welcome">
      <div class="wrapper">
        <div class="row">
          <div class="col"><h2 class="title">Dashboard (build 021123AZ01)</h2></div>

          <div class="col">
            <h3>{{this.greeting}},<br> <span>{{this.$root.user.Nombre}}</span> </h3>
          </div>
        </div>
      </div>
    </div>
    <div class="chart">
    <BarChart />
    </div>
    <div class="stats">
     
      <div class="row">
        <div class="col"><div class="box"><h2>{{total.listas}}</h2><h3>Cotizaciones Generadas</h3></div></div>
        <div class="col"><div class="box"><h2>{{ total.solicitadas }}</h2><h3>Cotizaciones solicitadas</h3></div></div>
      </div>

    </div>

    <div class="quotes">


      <div class="results">

        <ul>
          <li v-for="(item, i) in results" :key="'item' + i">
            <a href="javascript:void(0)">
              <h3>COT #{{ item.Folio }} - {{ item.Proyecto }}</h3>
              <h4>{{ item.NombreCliente }}</h4>
              <h5>{{ formatCurrency(item.Total) }} {{ item.TipoMoneda }}</h5>
              <h6><i data-feather="calendar"></i> {{ formatDate(item.FechaCotizacion) }}</h6>
                
              <span :class="item.ESTATUS1=='Generada' ? 'tag generada' : 'tag solicitada'">
                <i :data-feather="item.ESTATUS1=='Generada' ? 'check' : 'clock'"></i> 
                {{ item.ESTATUS1 }}
              </span>
              <span :class="item.tipo=='Urgente' ? 'tag urgente' : 'tag'" v-if="item.tipo=='Urgente'">
                <i data-feather="alert-circle"></i> 
                 Urgente
              </span>
                <span :class="item.Notificar==1 ? 'tag notificar' : 'tag'" v-if="item.Notificar==1">
                <i data-feather="bell"></i> 
                Lista
              </span>

            </a>
          </li>
        </ul>

      </div>

    </div>
  </section>
</template>

<script>
import HeaderNav from '@/components/HeaderNav.vue'
import feather from 'feather-icons'
import BarChart from '@/components/BarChart.vue'
import axios from 'axios'
import {_} from 'vue-underscore';

export default {
  name: 'HomeView',
  components: {
    HeaderNav,BarChart
  },
  data(){
    return{
      total : { listas: 0, solicitadas:0},
      results: [],
      stats: [],
      greeting : "",
    };
  },
  methods: {

    getGreeting(){
      let copy = '';
      var d = new Date();
      var time = d.getHours();

      if (time < 12) { copy = "Buenos días"; }
      if (time > 12) { copy = "Buenas tardes"; }
      if (time > 19) { copy = "Buenas noches"; }
      this.greeting = copy;
    },

    formatCurrency(val){
      let formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD'});
      return formatter.format(val);
    },


    /* SEARCH METHOD */
    getData(){
      let _this = this;
      axios({
        method: 'post',
        url: '/api/GetCotizacion',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        data: { usuario: this.$root.user.Usuario, Qty: 1000,UseDate: 0, DateIni: '',DateFin: '', notificar:0 }
      }).then((response) => {
        let solicitadas = _.where(response.data.Encabezado, {ESTATUS1: "Solicitada", Notificar: 0});
        let listas = _.where(response.data.Encabezado, {ESTATUS1 : "Generada"});
        
        this.total.listas = listas.length;
        this.total.solicitadas = solicitadas.length;
        this.results = solicitadas;
        _this.$refs.HeaderNav.setLoading(false);
      });
    },

    /* FORMAT DATE */
    formatDate(dateString){
      const date = new Date(dateString);
      return new Intl.DateTimeFormat('es-MX', {dateStyle: 'long'}).format(date);
        }
  },
  mounted() {
    this.$refs.HeaderNav.setLoading(true);
    feather.replace();
    this.getData();
    this.getGreeting();
  },
  updated(){
    this.$nextTick(function () {
      feather.replace();
    });
  },
}
</script>

<style scoped>
section { padding-bottom:50px; padding-top:20px;}

.welcome .row { font-size:0;}
.welcome .row .col { width:50%; display:inline-block; vertical-align:middle;}
.welcome .row .col h2.title { text-align:left;}
.welcome .row .col h3 { font-size:12px; padding-right:30px; position:relative; text-align:right;}
.welcome .row .col h3 span { font-weight:100; font-size:14px;}
.welcome .row .col h3 a { position:absolute; right:0;}

.chart { padding:10px;}



.welcome .row { font-size:0;}

.stats .row .col { width:50%; display:inline-block; vertical-align:middle; }
.stats .row .col { text-align:center; }
.stats .row .col:first-child .box { background: linear-gradient(45deg, #00830D 0%, #0DD145 100%); border-top: 2px solid #0DD145; border-bottom: 2px solid #0DD145;  color:#fff;}
.stats .row .col:last-child .box { background: linear-gradient(45deg, #9E0007 0%, #EA1C25 100%); border-top: 2px solid #EA1C25; border-bottom: 2px solid #EA1C25; color:#fff; }
.stats .row .col .box { background-color:#ccc;  padding:8px; min-height:97px;}
.stats .row .col .box h2 { font-size:30px;}
.stats .row .col .box h3{ font-size:13px; padding-left:10px; padding-right:10px;}

.results { padding:20px;}
.results .row { margin-bottom:20px;}
.results .row .col { width:50%; display:inline-block; vertical-align:middle; font-size:14px;}
.results .row .col.right { text-align:right;}
.results ul { margin-left:-20px; margin-right:-20px; margin-bottom:80px;}
.results ul li { border-top:#e4e4e4 solid 2px; text-align: left;  }
.results ul li:odd {}
.results ul li:even { background-color:#ccc;}
.results ul li a { padding:10px;  display:block; position:relative; padding-left:15px;}
.results ul li span.btn { width:32px; height:32px; display:block; position:absolute; right:5px; top:30px; color:#ccc; text-align:center; line-height:42px; }
.results ul li span.btn svg { width:24px; height:24px;}
.results ul li h3 { font-size:14px; color:#000; font-weight: bold;}
.results ul li h4 { font-size:12px; color:#595959; font-weight: 500; margin-bottom:5px; }
.results ul li h5 { color:#00830D; text-transform:uppercase; margin-bottom: 5px;; }
.results ul li h6 { color:#424242; margin-top:5px; font-weight:300; font-size:12px; font-weight: 500; margin-bottom:5px;}
.results ul li h6 svg { width:16px; height: 16px; vertical-align: top; margin-top:0px; color:#999;}

.results ul li span.tag { background-color:#e5e5e5; color:#fff; border-radius:3px; padding:3px 6px; font-size:11px; font-weight: 600;display:inline-block;  letter-spacing:.03em; margin-right:10px;}
.results ul li span.tag svg { width:14px; height: 14px; vertical-align: top; margin-top:0px; }
.results ul li span.tag.generada { background-color: #e6f9eb; color:#2d642f;  }
.results ul li span.tag.solicitada { background-color: #faeeef; color:#df5965; border:#ef737e solid 1px; }
.results ul li span.tag.urgente { background-color: #fff9ec; color:#ffa801; border:#ffa801 solid 1px; }
.results ul li span.tag.notificar { background-color: #fff1ec; color:#ff946f; border:#ff946f solid 1px; }

</style>
