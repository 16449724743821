<template>
   <router-view/>
    <footer-nav v-if="this.isLogged" />

</template>

<script>
import FooterNav from '@/components/FooterNav.vue'
// eslint-disable-next-line no-unused-vars
import OneSignal from '@onesignal/onesignal-vue3';


export default {
    name: 'App',
    metaInfo: {
      title: 'My Awesome Webapp',
      titleTemplate: null,
    },
    components: {
      FooterNav,
    },
    data(){
      return{
        isLogged : false,
        deferredPrompt: null,
        user: null,
      }
    },
    beforeMount() {
      //if(!this.isLogged){ this.$router.push('login'); }
      if(this.isLogged==false){
        this.user = JSON.parse(localStorage.getItem("userToken"));
        if(this.user!==null){ 
          this.isLogged =true;
          
        }else{
          this.$router.push('login');
        }
      }
    },
    beforeCreate() {
      
      
      
    },
    created(){ },
    mounted(){

      let OS = this.$OneSignal;
      this.captureEvent();
      
      this.$OneSignal.getUserId(function(sid){ 
        if(sid==null) { 
         OS.showNativePrompt();
        }

      })
      
      
      this.$OneSignal.getNotificationPermission(function(e){ 
        if(e=="default"){ 
          //notiene 
        }
        if(e=="granted"){
          console.log("Acceso");
          
   
        }
      });

  

      this.$OneSignal.on("permissionPromptDisplay", (e) => {
        console.warn("permissionPromptDisplay", e)
      })
       // eslint-disable-next-line
      this.$OneSignal.on("subscriptionChange", e => {
        //this.$OneSignal.getUserId(function(userId) {
          //         alert(userId);
        //});
      })
      this.$OneSignal.on("notificationDisplay", e => {
        console.warn("notificationDisplay", e);
        
      })
      this.$OneSignal.on("notificationDismiss", e => {
        console.warn("notificationDismiss", e);


      })
    },
    methods: {
      
      setLogged(val,user) {
        this.user = user;
        this.isLogged = val;
      },

      showPrompt(){
        this.$OneSignal.showSlidedownPrompt(); 
      },
      captureEvent() {
      window.addEventListener('beforeinstallprompt', (e) => {
        e.preventDefault()
        this.deferredPrompt = e
      });
    },




    },
  }
</script>

<style>
* { box-sizing:border-box;}
body { margin:0; padding:0; max-width:768px; margin:auto; box-shadow:0px 0px 10px rgba(0,0,0,.15); min-height:100vh;}
html {  background-color:#fbfbfb; }
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  padding-top:50px;
}
.wrapper { padding:0px 25px;}
input { -webkit-appearance: none; outline: none; border:none;}
a { text-decoration: none;}
nav {
}

nav a {
  font-weight: bold;
  color: #1EB0ED;
}

nav a.router-link-exact-active {
  color: #1EB0ED;
}
p.left { text-align:left;}
h1,h2,h3,h4,h5,h6,h7 { margin:0; padding:0;}
h2.title { font-size:14px; color:#5B5B5B;}
h2.title.left { text-align:left;}
h2.title.center { text-align:center;}
ul { margin:0; padding:0; list-style:none; }
a.btn.default { background: linear-gradient(180deg, #00A8F4 0%, #006B9B 100%); color:#fff; border-radius:3px; padding:5px; font-size:11px; text-transform:uppercase; font-weight:bold }
</style>
