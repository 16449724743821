<template>
  <HeaderNav title="COTIZACIÓN" ref="HeaderNav" />
  <div class="overlay off" ref="overlay" @click="onOverlayClick"></div>

  <div class="modal requestProduct off" ref="modal">
    <div class="window done" ref="window_done">
      
      <h2>Cotización #{{ this.id_cotizacion }} <span>Generada</span></h2>
      <p >¿Desea enviar su cotizacción vía correo?</p>

      <div class="scroll">

        <div class="cart">
      <div class="row">
        <h2 class="title left">{{this.cart.length}} PARTIDAS</h2>
      </div>
      <ul>
        <li v-for="(item, i) in cart" :key="'item' + i" v-bind:class="{ expired: item.EXPIRADO }">
        <div class="item nmr">
          <div class="post">

            <h3>{{ item.CATALOGO }}</h3>
            <h4>Marca: <span>{{ item.MARCA }}</span></h4>
            <h4>Tiempo Entrega: <span>{{ item.tiempoEntregaF }}</span></h4>
            <p>{{ item.DESCRIPCION }}</p>
          </div>
        </div>

        </li>
      </ul>
    </div>
      </div>

      <div class="actions">
        <div class="row">
          <div class="col"><a href="javascript:void(0)" class="btn cancel" @click="cancelSend">CANCELAR</a></div>
          <div class="col right"><a href="javascript:void(0)" class="btn" @click="onClickSend">ENVIAR</a></div>
        </div>
      </div>

    </div>
    <div class="window solicitar" ref="window_solicitar">
      <form  autocomplete="off">
        <h2>Solicitud de cátalogo</h2>
        <br>
        <div class="row">
          <div class="col">
            <label>Cátalogo</label><input type="text"  v-model="inputSolCat" ref="inputSolCat"/>
          </div>
          <div class="col">
            <label>Cantidad</label><input type="number" pattern="[0-9\/]*" v-model="inputSolCant" ref="inputSolCant"/>
          </div>
        </div>

        <div class="row">

          <div class="col">
            <label>Nivel de Precio</label>
            <select v-model="inputSolLista" ref="inputSolLista">
               <option value="" selected="selected"></option>
               <option value="0" v-if="this.$root.user.UsaPB==1">Precio Base</option>
               <option value="1" v-if="this.$root.user.UsaP1==1">Precio 1</option>
               <option value="2" v-if="this.$root.user.UsaP2==1">Precio 2</option>
               <option value="3" v-if="this.$root.user.UsaP3==1">Precio 3</option>
               <option value="4" v-if="this.$root.user.UsaP4==1">Precio 4</option>
               <option value="5" v-if="this.$root.user.UsaP5==1">Precio 5</option>
              </select>
          </div>
          <div class="col">
            <label>Marca</label>
            <select v-model="inputSolMarca" ref="inputSolMarca">
              <option v-for="(item, i) in marcas" :key="'item' + i">{{item.MARCA}}</option>
            </select>
          </div>
        </div>

        <div class="actions">
          <div class="row">
            <div class="col">
              <a href="javascript:void(0)" class="btn cancel" @click="cancelRequest()">CANCELAR</a>
            </div>
            <div class="col right"><a href="javascript:void(0)" class="btn" @click="onProductRequest">ACEPTAR</a></div>
          </div>
         
          
        </div>
    </form>
    </div>
  </div>

  <section class="cotizacion fixed">



  <div class="search">
    <div class="inputWrapper">
      <input type="search" placeholder="Búsqueda por catálogo" v-model="searchInput" ref="searchInput"  @input='evt=>searchInput=evt.target.value'  @keyup="initSearch(this)" @click="onSearchClick(this)"  autocapitalize="off"/>
    </div>

    <div class="results">
      <div class="notfound" ref="notfound">
        <p align="center">No se encontraron resultados. </p>
        <a href="javascript:void(0)" class="btn small" @click="openRequest()">SOLICITAR CÁTALOGO</a>
      </div>
     
      <ul>
        <li v-for="(item, i) in results" :key="'item' + i"><a href="javascript:void(0)" @click="onClickAdd(item)"><img :src="item.IMAGEN ? 'data:image/jpeg;base64,'+item.IMAGEN : '/img/placeholder.png'"/><h3>{{ item.CATALOGO }}</h3><h4>{{ item.MARCA }}</h4><p>{{ item.DESCRIPCION }}</p></a></li>
      </ul>
    </div>
  </div>

  <div class="info">
    <form id="frmCotizacion">
      <div class="row">
        <div class="col">
          <label>TIPO</label>
          <select v-model="tipoSelect">
            <option>Normal</option>
            <option>Urgente</option>
            <option>Compra</option>
          </select>
        </div>
        <div class="col">
          <label>PROYECTO</label><input type="text" v-model="proyectoInput" ref="proyectoInput"/>
        </div>
      </div>
        <div class="row">
          <div class="col">
            <label>CLIENTE</label>
            <input type="search" v-model="clienteInput"  @input='evt=>clienteInput=evt.target.value' @keyup="initClientSearch(this)" @click="onClientSearchClick(this)"  ref="clienteInput" class="zoom" />
            
            <div class="clientResult">
              <ul>
                <li v-for="(item, i) in clientResult" :key="'item' + i"><a href="javascript:void(0)" @click="onClickLoad(item)">{{item.NombreCliente}} ({{item.ContactoCliente}}) <br><span>{{item.email}}</span></a></li>
              </ul>
            </div>
          </div>
          <div class="col"><label>CONTACTO (NOMBRE)</label><input type="text" v-model="contactoInput" ref="contactoInput"/></div>
        </div>
        <div class="row">
          <div class="col"><label>TELÉFONO</label><input type="text" v-model="telefonoInput" ref="telefonoInput"></div>
          <div class="col"><label>E-MAIL (NOMBRE)</label><input type="text" v-model="correoInput" ref="correoInput"/></div>
        </div>

        <div class="row">
          <div class="col"><label>MONEDA</label>
            <select v-model="monedaSelect" @change="getTotal()" disabled>
              <option value="MXN" selected>MXN</option>
              <option value="USD">USD</option>
            </select>
          </div>
            <div class="col"><label>CONDICIONES DE PAGO</label><input type="text" v-model="condicionesInput" ref="condicionesInput"/></div>
        </div>

        <div class="row last">
          <div class="col">
            <label>TOTAL DE PARTIDAS</label>
            <h2 class="total">{{cart.length}}</h2>>
          </div>
          <div class="col">
            <label>SUBTOTAL</label>
            <h2 class="total">{{total}} <sup>{{monedaSelect}}</sup></h2>>
          </div>
            
        </div>

        <div class="alert" ref="alert">¡ERROR! - <span ref="errormsg"></span></div>

      </form>

      <div class="actions">
        <div class="row">
          <div class="col"><a href="javascript:void(0)" class="btn cancel large" @click="cancel(true)">CANCELAR</a></div>
          <div class="col right" align="right"><a href="javascript:void(0)" class="btn accept large" @click="onClickSave">GENERAR</a></div>
        </div>
      </div>

    </div>

    <div class="cart">
      <div class="row">
        <h2 class="title left">{{this.cart.length}} PARTIDAS</h2>
      </div>
      <ul>
        <li v-for="(item, i) in cart" :key="'item' + i" v-bind:class="{ expired: item.EXPIRADO, requested : (item.DETALLE.esNuevo==1) }">
        <a href="javascript:void(0)" @click="onClickRem(i)" class="btn remove"><span><i data-feather="trash"></i></span></a>
        <div class="item">
          <div class="post">
          <div class="preview">
            
            <img :src="item.IMAGEN ? 'data:image/jpeg;base64,'+item.IMAGEN : '/img/placeholder.png'"/>
          </div>
            <h3>{{ item.CATALOGO }}</h3>
            <h4>Marca: <span>{{ item.MARCA }}</span></h4>
            <h4 v-if="item.tiempoEntregaF!==''">Tiempo Entrega: <span>{{ item.tiempoEntregaF }}</span></h4>
            <p>{{ item.DESCRIPCION }}</p>
          </div>
        </div>

        <form>
          <div class="row">
            <div class="col">
              <label>PRECIO</label>
              <div class="selector=" v-if="item.DETALLE.esNuevo==0">
                <select @change="onChangeItem(i,'precio',$event)" data-moneda="MXN" :class="this.monedaSelect=='MXN' ? '' : 'hidden'">
                  <option value="" data-lista="-1" :selected="item.DETALLE.lista==null"></option>
                  <option :value="item.MXPRECIO0" :selected="item.DETALLE.lista==0" data-lista="0" v-if="this.$root.user.UsaPB==1">Precio Base -  {{ formatCurrency(item.MXPRECIO0) }} MXN</option>
                  <option :value="item.MXPRECIO1" :selected="item.DETALLE.lista==1" data-lista="1" v-if="this.$root.user.UsaP1==1">Precio 1 - {{ formatCurrency(item.MXPRECIO1) }} MXN</option>
                  <option :value="item.MXPRECIO2" :selected="item.DETALLE.lista==2" data-lista="2" v-if="this.$root.user.UsaP2==1">Precio 2 - {{ formatCurrency(item.MXPRECIO2) }} MXN</option>
                  <option :value="item.MXPRECIO3" :selected="item.DETALLE.lista==3" data-lista="3" v-if="this.$root.user.UsaP3==1">Precio 3 - {{ formatCurrency(item.MXPRECIO3) }} MXN</option>
                  <option :value="item.MXPRECIO4" :selected="item.DETALLE.lista==4" data-lista="4" v-if="this.$root.user.UsaP4==1">Precio 4 - {{ formatCurrency(item.MXPRECIO4) }} MXN</option>
                  <option :value="item.MXPRECIO5" :selected="item.DETALLE.lista==5" data-lista="5" v-if="this.$root.user.UsaP5==1">Precio 5 - {{ formatCurrency(item.MXPRECIO5) }} MXN</option>
                </select>

                <select @change="onChangeItem(i,'precio',$event)" data-moneda="USD" :class="this.monedaSelect=='USD' ? '' : 'hidden'">
                  <option :value="item" data-lista="-1" :selected="item.DETALLE.lista=='-1'"></option>
                  <option :value="item" data-lista="0" :selected="item.DETALLE.lista==0" v-if="this.$root.user.UsaPB==1">Precio Base -  {{ formatCurrency(item.USPRECIO0) }} USD</option>
                  <option :value="item.USPRECIO1" data-lista="1" :selected="item.DETALLE.lista==1" v-if="this.$root.user.UsaP1==1">Precio 1 - {{ formatCurrency(item.USPRECIO1) }} USD</option>
                  <option :value="item.USPRECIO2" data-lista="2" :selected="item.DETALLE.lista==2" v-if="this.$root.user.UsaP2==1">Precio 2 - {{ formatCurrency(item.USPRECIO2) }} USD</option>
                  <option :value="item.USPRECIO3" data-lista="3" :selected="item.DETALLE.lista==3" v-if="this.$root.user.UsaP3==1">Precio 3 - {{ formatCurrency(item.USPRECIO3) }} USD</option>
                  <option :value="item.USPRECIO4" data-lista="4" :selected="item.DETALLE.lista==4" v-if="this.$root.user.UsaP4==1">Precio 4 - {{ formatCurrency(item.USPRECIO4) }} USD</option>
                  <option :value="item.USPRECIO5" data-lista="5" :selected="item.DETALLE.lista==5" v-if="this.$root.user.UsaP5==1">Precio 5 - {{ formatCurrency(item.USPRECIO5) }} USD</option>
                </select>
              </div>
              <div class="request" v-if="item.DETALLE.esNuevo==1">
                <p>{{ ((item.DETALLE.lista==0) ? "Precio Base" : "Nivel de precio "+item.DETALLE.lista) }}</p>
              </div>

            </div>
            <div class="col">
              <label>CANTIDAD</label>
              <input type="number" d
              class="small" min="1" pattern="[0-9]*" @change="onChangeItem(i,'cantidad',$event)" @keyup="onChangeItem(i,'cantidad',$event)" :value="item.DETALLE.cantidad"/>
            </div>
          </div>
          <div class="row mbzero">
            <div class="col">
              <label>CLAVE</label>
              <input type="text" placeholder="" class="small" @change="onChangeItem(i,'clave',$event)" :value="item.DETALLE.clave" maxlength="5"/>
            </div>
            <div class="col">
              
            </div>
          </div>
          <p class="expired" v-if="item.EXPIRADO==1"><i data-feather="alert-triangle"></i>Precio Expirado,  <span>pendiente de actualización</span></p>
          <p class="requested" v-if="item.DETALLE.esNuevo==1"><i data-feather="arrow-right-circle"></i>Cátalogo Solicitado</p>
          
        </form>

        </li>
      </ul>
    </div>




</section>
</template>

<script>
import feather from 'feather-icons'
import HeaderNav from '@/components/HeaderNav.vue'
import axios from 'axios'
import {_} from 'vue-underscore';

const abortController = new AbortController()



export default {
  name: 'CotizacionView',
  components: {
    HeaderNav,
  },
  data(){
    return{
      inputSolCant: '',
      inputSolCat: '',
      inputSolLista: '',
      inputSolMarca:'',
      id_cotizacion: '',
      tipoSelect: 'Normal',
      searchInput: '',
      clienteInput: '',
      contactoInput: '',
      correoInput: '',
      telefonoInput: '',
      cotSolicitada:0,
      proyectoInput: '',
      condicionesInput: '',
      monedaSelect: 'MXN',
      results: [],
      clientResult : [],
      cart:[],
      marcas:[],
      total:"0.0",
 
    };
  },
  mounted() {
    feather.replace();
    this.getMarcas();

  },
  updated(){
    this.$nextTick(function () {
      feather.replace();
    });
  },

  methods : {

  showModal : function(window){
    this.$refs.modal.classList.remove("off");
    this.$refs.notfound.classList.remove("on");
    
    this.$refs.window_done.classList.remove("on");
    this.$refs.window_solicitar.classList.add("on");
    
    if(window=="solicitar"){
      this.$refs.window_done.classList.remove("on");
      this.$refs.window_solicitar.classList.add("on");
    }else{
      this.$refs.window_done.classList.add("on");
      this.$refs.window_solicitar.classList.remove("on");
    }
    
  },
  closeModal : function(){
    this.$refs.modal.classList.add("off");
    this.$refs.overlay.classList.add("off");
  },

  /* REQUEST MARCAS */
  getMarcas : function(){
    axios({
      method: 'post',
      url: '/api/GetMarca',
      headers: { 
        'content-type': 'application/x-www-form-urlencoded',
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0',
      },
      data: {  },
    }).then((response) => {
      this.marcas = response.data.Marca;
    });
  },

  formatCurrency(val){
    let formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD'});
    return formatter.format(val);
  },

  onChangeItem: function(idx,target,event){
    if(target=="precio"){
      let moneda = event.target.getAttribute('data-moneda');
      if(moneda=="MXN"){
        console.log("Ruta 1");
        //event.target.nextElementSibling.options[event.target.selectedIndex].selected = 'selected'
      }else{
        console.log("Ruta 2");
        event.target.previousElementSibling.options[event.target.selectedIndex].selected = 'selected'
      }
      this.cart[idx].DETALLE.precio = event.target.value;
      if(event.target.options[event.target.selectedIndex].getAttribute('data-lista')=="-1"){
        console.log("entr por aca....");
        this.cart[idx].DETALLE.lista = "-1";
      }else{
        console.log("Ruta 3");
      this.cart[idx].DETALLE.lista = parseInt(event.target.options[event.target.selectedIndex].getAttribute('data-lista'));
      }

    }else if(target=="tiempo_entrega"){
      this.cart[idx].DETALLE.tiempo_entrega = event.target.value;
    }else if(target=="cantidad"){
        this.cart[idx].DETALLE.cantidad = event.target.value;
    }else if(target=="clave"){
      this.cart[idx].DETALLE.clave = event.target.value;
    } 
    this.getTotal();
  },

  cancelSend : function(){

    
      this.id_cotizacion = '';
      this.tipoSelect = 'Normal';
      this.searchInput = '';
      this.clienteInput = '';
      this.contactoInput = '';
      this.correoInput = '';
      this.telefonoInput = '';
      this.proyectoInput = '';
      this.condicionesInput = '';
      this.monedaSelect = 'MXN';
      this.cart = [];
      this.total = 0;
      this.$refs.HeaderNav.incrementCount();
      this.closeModal();

  },

  cancel : function(reset){
    if(reset){
    
      if(confirm("¿Esta seguro de cancelar la cotización actual?")){

      this.$refs.proyectoInput.classList.remove("error"); 
      this.$refs.clienteInput.classList.remove("error"); 
      this.$refs.contactoInput.classList.remove("error"); 
      this.$refs.telefonoInput.classList.remove("error");
      this.$refs.correoInput.classList.remove("error"); 
      this.$refs.condicionesInput.classList.remove("error"); 
      this.$refs.alert.classList.remove("on");
  
      this.tipoSelect = 'Normal';
      this.searchInput = '';
      this.clienteInput = '';
      this.contactoInput = '';
      this.correoInput = '';
      this.telefonoInput = '';
      this.proyectoInput = '';
      this.condicionesInput = '';
      this.monedaSelect = 'MXN';
      this.cart = [];
      this.total = 0;
      this.$refs.HeaderNav.incrementCount();
    }
  }else{
    this.$refs.proyectoInput.classList.remove("error"); 
      this.$refs.clienteInput.classList.remove("error"); 
      this.$refs.contactoInput.classList.remove("error"); 
      this.$refs.telefonoInput.classList.remove("error");
      this.$refs.correoInput.classList.remove("error"); 
      this.$refs.condicionesInput.classList.remove("error"); 
      this.$refs.alert.classList.remove("on");
  
      this.tipoSelect = 'Normal';
      this.searchInput = '';
      this.clienteInput = '';
      this.contactoInput = '';
      this.correoInput = '';
      this.telefonoInput = '';
      this.proyectoInput = '';
      this.condicionesInput = '';
      this.monedaSelect = 'MXN';
      this.cart = [];
      this.total = 0;
      this.$refs.HeaderNav.incrementCount();
  }
  },

  onClickLoad: function(item){
    this.clientResult = [];
    this.clienteInput = item.NombreCliente;
    this.contactoInput = item.ContactoCliente;
    this.telefonoInput = item.TelCliente;
    this.correoInput = item.email;
    this.condicionesInput = item.CondicionesPago;
  },

  onClickAdd : function(el){
    //let exists = _.findWhere(this.cart, { IDPRODUCTO: el.IDPRODUCTO });
    //if(exists){ alert("Ya existe ese producto en su lista"); return; }
    this.cart.push(el);
    this.cart[(this.cart.length-1)].DETALLE = {
      tiempo_entrega: '',
      cantidad:1,
      clave:'',
      lista:'-1',
      esNuevo:0,
      expirado: el.EXPIRADO,
    };
    this.results = '';
    this.$refs.HeaderNav.incrementCount();
    this.$refs.overlay.classList.add("off");
    this.searchInput = '';
    this.getTotal();
  },

  openRequest(){
    this.inputSolCat = this.searchInput;
    this.showModal('solicitar');
  },

  onProductRequest : function(){
    //let exists = _.findWhere(this.cart, { CATALOGO: this.inputSolCat });
    //if(exists){ alert("El cátalogo ya se encuentra solicitado en su lista"); return; }
    let el = { IDPRODUCTO: '',}
    this.cart.push(el);
    this.cart[(this.cart.length-1)].DETALLE = {
      tiempo_entrega: '',
      cantidad: this.inputSolCant,
      clave:'',
      lista:this.inputSolLista,
      esNuevo:1,
      expirado: 0,
      precio: 0,
    }

    this.cart[(this.cart.length-1)].MXPRECIO0 = 0;
    this.cart[(this.cart.length-1)].MXPRECIO1 = 0;
    this.cart[(this.cart.length-1)].MXPRECIO2 = 0;
    this.cart[(this.cart.length-1)].MXPRECIO3 = 0;
    this.cart[(this.cart.length-1)].MXPRECIO4 = 0;
    this.cart[(this.cart.length-1)].MXPRECIO5 = 0;

    this.cart[(this.cart.length-1)].USPRECIO0 = 0;
    this.cart[(this.cart.length-1)].USPRECIO1 = 0;
    this.cart[(this.cart.length-1)].USPRECIO2 = 0;
    this.cart[(this.cart.length-1)].USPRECIO3 = 0;
    this.cart[(this.cart.length-1)].USPRECIO4 = 0;
    this.cart[(this.cart.length-1)].USPRECIO5 = 0;

    this.cart[(this.cart.length-1)].CATALOGO = this.inputSolCat;
    this.cart[(this.cart.length-1)].MARCA = this.inputSolMarca;
    this.cart[(this.cart.length-1)].IDPRODUCTO = 0;
    this.cart[(this.cart.length-1)].DESCRIPCION = "";
    this.cart[(this.cart.length-1)].tiempoEntregaF = '';

 
    this.results = '';
    this.$refs.HeaderNav.incrementCount();
    this.$refs.overlay.classList.add("off");
    this.searchInput = '';
    this.getTotal();
    this.cancelRequest();
  },

  cancelRequest(){
      this.$refs.inputSolCant.classList.remove("error"); 
      this.$refs.inputSolCat.classList.remove("error"); 
      this.$refs.inputSolLista.classList.remove("error"); 
      this.$refs.inputSolMarca.classList.remove("error");

      this.inputSolCat = '';
      this.inputSolCant = '';
      this.inputSolLista = '';
      this.inputSolMarca = '';
      this.$refs.notfound.classList.remove("on");
      this.closeModal('solicitar');
  },

  getTotal : function(){
    let items = this.cart;
    let subtotal = { USD: 0.0, MXN:0.0 }
    
    for(let i=0;i<items.length;i++){
      if(this.monedaSelect=="MXN"){
        //console.log("Articulo MXN: "+items[i].CATALOGO+" ($"+items[i]["MXPRECIO"+items[i].DETALLE.lista]+"*"+items[i].DETALLE.cantidad+")");

      }else{
        //console.log("Articulo USD: "+items[i].CATALOGO+" ($"+items[i]["USPRECIO"+items[i].DETALLE.lista]+"*"+items[i].DETALLE.cantidad+")");

      }
      subtotal.MXN += items[i]["MXPRECIO"+items[i].DETALLE.lista]*parseInt(items[i].DETALLE.cantidad);
      subtotal.USD += items[i]["USPRECIO"+items[i].DETALLE.lista]*parseInt(items[i].DETALLE.cantidad);
    }
    if(isNaN(subtotal.MXN)){ subtotal.MXN = 0.0; }
    if(isNaN(subtotal.USD)){ subtotal.USD = 0.0; }
    this.total = this.formatCurrency(subtotal = subtotal[this.monedaSelect]);
    return this.total;
  },

  onClickRem : function(idx){
    if(confirm("Confirmar que desea eliminar "+this.cart[idx].CATALOGO)){
      this.cart.splice(idx, 1);
    }
    this.getTotal();
  },


  onOverlayClick : function(){
    this.$refs.overlay.classList.add("off");
    this.$refs.notfound.classList.remove("on");
    this.results = '';
    this.searchInput = '';

  },

  initClientSearch(){
    this.clientResult = '';     
    if(this.clienteInput.length<=0){  this.clientResult = ''; }
    if(this.clienteInput.length<=2){ return false;}
    setTimeout(this.onSearchClient,500);
  },

  async onSearchClient(){
    this.$refs.HeaderNav.setLoading(true);
   
    await axios({
      method: 'post',
      url: '/api/GetClient',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: { Cliente: this.clienteInput },
    }).then((response) => {
      this.clientResult = '';
      this.clientResult = response.data.Cliente;
      this.$refs.HeaderNav.setLoading(false);

    });
  },

  onClientSearchClick(){
    this.clientResult = '';     
    this.$refs.HeaderNav.setLoading(false);
    this.clienteInput = '';
  },

  onSearchClick(){
    this.results = '';
    this.$refs.notfound.classList.remove("on");
    this.$refs.overlay.classList.add("off");
    this.$refs.HeaderNav.setLoading(false);
    this.searchInput = '';
  },

  initSearch(){
    this.results = '';
    this.$refs.HeaderNav.setLoading(true);
    this.$refs.notfound.classList.remove("on");
    this.$refs.overlay.classList.remove("off");
    if(this.searchInput.length<=0){ 
      this.$refs.overlay.classList.add("off");
      this.$refs.HeaderNav.setLoading(false);
    }
    if(this.searchInput.length<=2){ 
      this.$refs.HeaderNav.setLoading(false);
      return false;
    }
    setTimeout(this.onSearch,500);
  },

  async onSearch(){

      //abortController.abort();

      await axios({
        method: 'post',
        url: '/api/GetProduct',
        headers: { 'content-type': 'application/x-www-form-urlencoded',  },
        data: { Producto: this.searchInput.toUpperCase() },
        signal: abortController.signal
      }).then((response) => {   
        this.results = '';
        this.results = response.data.Producto;
        if(this.results.length<=0){
          this.$refs.notfound.classList.add("on");
        }else{
          this.$refs.notfound.classList.remove("on");
        }
        this.$refs.HeaderNav.setLoading(false);
      }).catch(function(){ });
  },

  onClickSend: function(){
    
    // Cotización General
    let formData = {
      idcotiza: this.id_cotizacion,
      correo: this.correoInput,

    }


    axios({
      method: 'post',
      url: '/api/GetPdf',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: formData,
    }).then(() => {
       alert("Su cotización ha sido enviada");
       this.closeModal();
       this.cancelSend();
    });
  },

  onClickSave: function(){
    
    // Cotización General
    let formData = {
      tipo: this.tipoSelect,
      proyecto: this.proyectoInput,
      cliente: this.clienteInput,
      contacto: this.contactoInput,
      telefono: this.telefonoInput,
      correo: this.correoInput,
      condiciones: this.condicionesInput,
      moneda: this.monedaSelect,
      usuario: this.$root.user.Usuario,
    }

  
  

    //validate
    let flag = true;
    this.$refs.proyectoInput.classList.remove("error"); 
    this.$refs.clienteInput.classList.remove("error"); 
    this.$refs.contactoInput.classList.remove("error"); 
    this.$refs.telefonoInput.classList.remove("error");
    this.$refs.correoInput.classList.remove("error"); 
    this.$refs.condicionesInput.classList.remove("error"); 
    this.$refs.alert.classList.remove("on");

    if(this.cart.length<=0){ 
      this.$refs.errormsg.innerHTML = 'No tiene ninguna partida seleccionada';
      this.$refs.alert.classList.add("on");
      return false;
    }
   
    let checkEmptyList = _.filter(this.cart, function(item){ return item.DETALLE.lista=="-1" });
    if(checkEmptyList.length>0){ 
      this.$refs.errormsg.innerHTML = 'Tiene ('+checkEmptyList.length+') partida'+((checkEmptyList.length>=2) ? "s" : "")+' sin precio asignado';
      this.$refs.alert.classList.add("on");
      return false;
    }

    if(formData.proyecto==""){  this.$refs.proyectoInput.classList.add("error"); flag = false; }
    if(formData.cliente==""){  this.$refs.clienteInput.classList.add("error"); flag = false; }
    if(formData.contacto==""){  this.$refs.contactoInput.classList.add("error"); flag = false; }
    if(formData.telefono==""){  this.$refs.telefonoInput.classList.add("error"); flag = false; }
    if(formData.correo==""){  this.$refs.correoInput.classList.add("error"); flag = false; }
    if(formData.condiciones==""){  this.$refs.condicionesInput.classList.add("error"); flag = false; }


    if(!flag){ 
      this.$refs.errormsg.innerHTML = 'Los campos marcados en rojo son obligatorios';
      this.$refs.alert.classList.add("on");
      return false;
    }

    if(!confirm("¿Desea generar la cotización?")){ return false;  }
    


    axios({
      method: 'post',
      url: '/api/SetCotiza',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: formData,
    }).then((response) => {
      this.id_cotizacion = response.data.Cotizacion[0].Column1;

      for(var i=0;i<this.cart.length;i++){
        let precio = 0;
        if(this.monedaSelect=="MXN"){  
          precio = this.cart[i]["MXPRECIO"+this.cart[i].DETALLE.lista];
        }else{ 
          precio = this.cart[i]["USPRECIO"+this.cart[i].DETALLE.lista];
        }
        let detalle = {
          cotiza: this.id_cotizacion,
          catalogo: this.cart[i].CATALOGO,
          descripcion: this.cart[i].DESCRIPCION,
          cant: this.cart[i].DETALLE.cantidad,
          marca: this.cart[i].MARCA,
          precioUnitario: precio,
          tiempoEntrega: this.cart[i].DETALLE.tiempo_entrega,
          idproducto: this.cart[i].IDPRODUCTO,
          lista: this.cart[i].DETALLE.lista,
          clave: this.cart[i].DETALLE.clave,
          esNuevo: this.cart[i].DETALLE.esNuevo,
          expirado: this.cart[i].DETALLE.expirado,
          Partida: (i+1),
        }
        if(detalle.expirado==1){ this.cotSolicitada = 1;}
        this.setDetalle(detalle);
      }
       // mostrar notificación que fue creada con éxito.
       if(this.cotSolicitada==1){
         alert("Su cotización ha sido solicitada cuando este lista te noficaremos para su envío.");
         this.cancel();
         this.closeModal();
       }else{
        this.showModal("done");

       }
       
       //this.cancel(false);
    });
  },

  async setDetalle(detalle) {
    let response = await axios({
      method: 'post',
      url: '/api/SetCotizaDet',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: detalle,
    });
    return response.data;
  }

  }
}
</script>

<style scoped>
.hidden { display:none;}
* { box-sizing:border-box;}
input { text-indent:5px;}
select { text-indent:5px;}
.overlay { position:fixed; top:0; bottom:0; left:0; right:0; background-color:rgba(0,0,0,.80); width:100%; height:100%; z-index:10;  }
.overlay.off { display:none;}
.actions .row { font-size:0; margin-bottom:15px;}
.actions .row .col { width:50%; display:inline-block; vertical-align:top; text-align:left;}
.actions .row .col.right { text-align:right;}
a.btn {background: linear-gradient(180deg, #00A8F4 0%, #006B9B 100%); border-radius:100px; display:inline-block;  color:#fff; text-align:center; padding:10px 20px; font-size:11px; font-weight:bold; letter-spacing:1px; min-width:120px; }
a.btn.large { display:inline-block; min-width:100px; }
a.btn.cancel {background: linear-gradient(180deg, #FF7675 0%, #D22728 100%);  }


.modal { position:fixed; top:0; left:0; right:0; bottom:0; background-color:rgba(0,0,0,.70); z-index:10000000; overflow-y: scroll;}
.modal.off { display:none;}
.modal  .window { background:#fff; width:90%; min-height:30px;  margin:auto; margin-top:80px; max-width:768px; display:none;}
.modal  .window.on { display:block;}
.modal  .window.done { padding:20px; max-width:400px;}
.modal  .window  form { padding:20px;}
.modal  .window  form .row { margin-bottom:20px;}
.modal  .window  form .row .col { width:50%; display:inline-block; vertical-align:top; text-align:left;}
.modal  .window  form .row .col:first-child { padding-right:10px;}
.modal  .window  form .row .col:last-child { padding-right:10px;}

.modal  .window  form .row .col.right { text-align:right;}
.modal  .window  form input { width:100%; height:35px; border:#e4e4e4 solid 1px;}
.modal  .window  form select { width:100%; height:35px; border:#e4e4e4 solid 1px;}

.cotizacion.blur { filter: blur(2px); }
.cotizacion  { padding-bottom:100px;}
.cotizacion.fixed .search { top:50px; left:0; right:0; position:fixed; height:65px;}
.cotizacion.fixed .info { padding-top:60px; }

.cotizacion .info {  background-color:#dfe4ea; }
.cotizacion .info h2 { margin-bottom:0px; color:#DA9D2C;}
.cotizacion .info form { padding:20px 10px; }
.cotizacion .info form .alert { background-color:#e74c3c; font-size:14px; padding:5px; color:#fff; font-weight:bold; display:none; border-radius:3px;opacity:0; transition: opacity 0.5s ease; margin-top:5px;
 }
.cotizacion .info form .alert.on { display:inline-block; opacity:1;  }
.cotizacion .info form .alert span { font-weight:500;}
.cotizacion .info form .row { font-size:0; margin-bottom:15px;}
.cotizacion .info form .row.last { margin-bottom:0px;}
.cotizacion .info form .row .col { width:50%; display:inline-block; vertical-align:top; text-align:left;}
.cotizacion .info form .row .col input { width:97%; height:30px; border:#a4b0be solid 1px; text-indent:5px;}
.cotizacion .info form .row .col input.error { border:#e74c3c solid 1px; background:url("~@/assets/warning.svg") right center no-repeat #fff; background-size:22px 22px; background-position: right 5px center; }
.cotizacion .info form .row .col h2 { font-size:16px;}
.cotizacion .info form .row .col h2.total { color:#666;}
.cotizacion .info form .row .col h2.total sup { font-size:10px;}
.cotizacion .info form .row .col label {color:#2f3542; text-transform: uppercase; font-weight:bold; font-size:12px; display:block; text-align:left;}
.cotizacion .info form .row .col select { width:97%; height:30px; border:#a4b0be solid 1px; text-indent:5px;}
.cotizacion .info form .row:last-child { margin-bottom: 0px;;}
.cotizacion .actions { padding:10px 10px; background-color: #a4b0be; }
.cotizacion .actions .row { margin-bottom: 0;}
.cotizacion .info .clientResult { position:relative; z-index:1; }
.cotizacion .info .clientResult ul { font-size:14px; position: absolute; background-color:#fff; width:95%; box-shadow:0px 0px 5px rgba(0,0,0,.15); max-height: 170px; overflow-y: scroll;}
.cotizacion .info .clientResult ul li { border-bottom:#e4e4e4 solid 1px; padding:4px;}
.cotizacion .info .clientResult ul li a { color:#000; font-weight: bold; font-size: 12px; padding-left:10px; display:block;}
.cotizacion .info .clientResult ul li a span { color:#777; font-size: 11px; font-weight: 300;}
.search { padding:15px 15px; position:relative; z-index:20; background: linear-gradient(180deg, #00A8F4 0%, #006B9B 100%); }
.search input { border:#ccc solid 1px; width:100%; height:35px; border-radius:6px; text-indent:10px; background:url("~@/assets/search.svg") right center no-repeat #fff; background-size:22px 22px; background-position: right 5px center; position:relative; }
.search input:focus { background:#fff;}
.search input::-webkit-search-cancel-button{
    -webkit-appearance: none;
    background:url("~@/assets/x.svg") right center no-repeat #fff;
    width:20px;
    height:20px;
}
.search .inputWrapper { position:relative; display:inline-block; width:100%; height:35px; border-radius:6px; }
.search .results { background-color:#fff;}

input.zoom { background:url("~@/assets/search.svg") right center no-repeat #fff; background-size:18px 18px; background-position: right 5px center; }
input.zoom:focus { background:#fff;}
input.zoom::-webkit-search-cancel-button{
    -webkit-appearance: none;
    background:url("~@/assets/x.svg") right center no-repeat #fff;
    width:20px;
    height:20px;
}

.results  { background:#fff; max-height:360px; overflow-y:scroll; border-radius:6px; }
.results .notfound { display:none; max-width:50%; margin:auto; padding-top:30px; padding-bottom:30px;}
.results .notfound p { margin-bottom:10px; display:block;}
.results .notfound.on { display:block;}
.results ul li { text-align: left; border-bottom:#ccc solid 1px; position:relative; overflow:hidden;  }
.results ul li a img { max-width:80px; position:absolute; left:5px; top:10px; border:#e4e4e4 solid 1px;}
.results ul li a { padding:6px;  display:block; position:relative; padding-left:90px;}
.results ul li span.btn { width:32px; height:32px; background: linear-gradient(180deg, #FF7675 0%, #D22728 100%); border-radius:100px; display:block; position:absolute; right:5px; top:5px; color:#fff; text-align:center; line-height:45px; }
.results ul li h3 { font-size:14px; color:#000; font-weight: bold;}
.results ul li h4 { font-size:13px; color:#757379; font-weight: 300; }
.results ul li p { color:#333; font-size:12px}
.results ul li h6 { color:#666; margin-top:5px; font-weight:300;}

.cart { padding:20px; }
.cart h2 { color:#999;}
.cart h2.title { font-size:12px;}
.cart .row { margin-bottom:10px;}
.cart .row.mbzero { margin-bottom:0px;}
.cart .row .col { width:50%; display:inline-block; vertical-align:middle; font-size:14px;}
.cart .row .col:first-child { width:70%;}
.cart .row .col:last-child { width:30%}
.cart .row .col.right { text-align:right;}
.cart ul li { border:#e4e4e4 solid 1px; border-radius:6px; text-align: left; background-color:#fff; box-shadow:0px 5px 8px rgba(0,0,0,.10); margin-bottom:30px;}
.cart ul li.expired { border:#F47C7C solid 1px; background-color:#FFF2F2; }
.cart ul li.expired p.expired { background:#D22728; color:#fff; padding:4px 6px; display:inline-block;  border-radius:3px; font-weight:600; font-size:11px; text-transform: uppercase; vertical-align:middle; margin-bottom:0px;}
.cart ul li.expired p.expired svg { width:13px; height: 13px; vertical-align: top; margin-right:4px;}

.cart ul li.requested { border:#ffc048 solid 1px; background-color:#fbf7df; }
.cart ul li.requested p.requested { background:#ffa801; color:#fff; padding:4px 6px; display:inline-block;  border-radius:3px; font-weight:600; font-size:11px; text-transform: uppercase; vertical-align:middle; margin-bottom:0px; }
.cart ul li.requested p.requested svg { width:13px; height: 13px; vertical-align: top; margin-right:4px;}

.cart ul li a { padding:10px;  display:block; position:relative;}
.cart ul li a.btn.remove { width:28px; height:28px; background: linear-gradient(180deg, #FF7675 0%, #D22728 100%); border-radius:100px; display:block !important; min-width:auto; position:absolute; right:6px; bottom:6px; color:#fff; text-align:center; z-index:9; padding:0px; line-height:34px; }
.cart ul li a.btn.remove span svg { width:16px; height:16px;}
.cart ul li h3 { font-size:14px; color:#000; font-weight: bold; margin-bottom:3px; text-transform:uppercase; }
.cart ul li h4 { font-size:13px; color:#757379; font-weight: 500; display:inline-block; margin-right:20px; margin-bottom:0px; }
.cart ul li h4:last-child { margin-bottom:10px;}
.cart ul li h4 span { color:#000;}
.cart ul li h4 span.pill { background-color:#e5e5e5; color:#333; border-radius:3px; padding:1px 6px; font-size:13px;}
.cart ul li p { color:#333; margin-top:7px; font-size:13px;}
.cart ul li h6 { color:#666; margin-top:5px; font-weight:300;}
.cart ul li .post { min-height:100px;}
.cart ul li .request p { font-weight:500;}

.cart ul li { padding:10px; position:relative;}
.cart ul li .item { padding-left:110px; position:relative;}
.cart ul li .item.nmr { padding-left:15px;}
.cart ul li .item .preview { border:#e4e4e4 solid 1px; min-height:100px; position:absolute; left:0px; max-height:100px; overflow:hidden; background-color:#f1f3f5;}
.cart ul li .item .preview img { width:100%; max-width:100px; }
.cart ul li form { margin-top:10px;}
.cart ul li form .row .col:first-child  { padding-right:10px;}
.cart ul li form .row .col:last-child  { padding-left:10px;}

.cart ul li form label { display:block; font-weight:700; font-size:12px; }
.cart ul li form input { border:#ccc solid 1px; width:100%; height:30px; }
.cart ul li form input.small { max-width:80px;}
.cart ul li form select { border:#ccc solid 1px; width:100%; height:30px;}




</style>
