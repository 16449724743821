<template>
  <Bar id="my-chart-id" :options="chartOptions" :data="chartData" v-if="loaded"/>
</template>

<script>
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
import axios from 'axios'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: 'BarChart',
  components: { Bar },
  data: () => ({
    loaded: false,
    chartData: null,
    chartOptions: { 
          maintainAspectRatio: false,
          responsive: true, 
        },
    totalCotizaciones : [],
  }),
  async mounted () {
    this.loaded = false

    try {

      axios({
        method: 'post',
        url: '/api/GetVentas',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        data: { usuario: this.$root.user.Usuario }
      }).then((response) => {
        let result = response.data.Detalle[0];
        this.totalCotizaciones.push(result.ene);
        this.totalCotizaciones.push(result.feb);
        this.totalCotizaciones.push(result.mar);
        this.totalCotizaciones.push(result.abr);
        this.totalCotizaciones.push(result.may);
        this.totalCotizaciones.push(result.jun);
        this.totalCotizaciones.push(result.jul);
        this.totalCotizaciones.push(result.ago);
        this.totalCotizaciones.push(result.sep);
        this.totalCotizaciones.push(result.oct);
        this.totalCotizaciones.push(result.nov);
        this.totalCotizaciones.push(result.dic);

        this.chartData =  {
          label: 'Data One',
          labels: [ 'Ene', 'Feb', 'Mar','Abr','May','Jun','Jul','Ago','Sep','Oct','Nov','Dic' ],
          datasets: [ { label: 'Total de Cotizaciones',backgroundColor: '#fecb00',data: this.totalCotizaciones } ]
        }
        
        this.loaded = true;
      });
      
    } catch (e) {
      console.error(e)
    }
  },
  methods : {
  
  },
}
</script>
