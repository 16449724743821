<template>
<footer>
  <nav>
    <router-link to="/"><i data-feather="home"></i> Home
</router-link> |
    <router-link to="/cotizacion"><i data-feather="shopping-cart"></i> Cotización</router-link>|
    <router-link to="/historial"><i data-feather="file-text"></i> Historial</router-link> |
    <router-link to="/ayuda"><i data-feather="help-circle"></i> Ayuda</router-link>
  </nav>
</footer>
</template>

  <script>
   import feather from 'feather-icons'

   export default {
  components: {

  },mounted() {
      feather.replace();
    }
}
  </script>

<style scoped>
footer {
  position:fixed; bottom: 0;
  height: 50px; background-color:#fff; width:100%; left:0; right:0; box-shadow:0px 0px 10px rgba(0,0,0,.35);
  z-index:10000000;
}

nav { display: flex; justify-content:space-around; min-height:100%; align-items: center; color:#e5e5e5; }
nav a { text-align:center; flex-direction: row;  font-size:10px; padding:5px; text-align:center; text-transform:uppercase; font-weight:500; color:#5B5B5B; }
nav a svg {  width:16px; height:16px; display:block; margin:auto; margin-bottom:4px;}
</style>
