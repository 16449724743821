import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import CotizacionView from '../views/CotizacionView.vue'
import AyudaView from '../views/AyudaView.vue'
import HistorialView from '../views/HistorialView.vue'
import AuthView from '../views/AuthView.vue'
import ConfiguracionView from '../views/ConfiguracionView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: { requiresAuth: true },
  },
  {
    path: '/ayuda',
    name: 'ayuda',
    component: AyudaView,
    meta: { requiresAuth: true },
  },
  {
    path: '/cotizacion',
    name: 'cotizacion',
    component: CotizacionView,
    meta: { requiresAuth: true },
  },
  {
    path: '/historial',
    name: 'historial',
    component: HistorialView,
    meta: { requiresAuth: true },
  },
  {
    path: '/login',
    name: 'login',
    component: AuthView
  },
  {
    path: '/configuracion',
    name: 'configuracion',
    component: ConfiguracionView,
    meta: { requiresAuth: true },
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  mode: 'hash',
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if(localStorage.getItem("userToken") !== null){
      next();
    }else{
      next({ path: "/login", params: { nextUrl: to.fullPath }, });
    }
  } else {
    next();
  }
});

/*
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem("userToken") == null) {
      next({
        path: "/login",
        params: { nextUrl: to.fullPath },
      });
    } else {
      if (!store.state.isAuthenticated) {
        next({
          path: "/login",
          params: { nextUrl: to.fullPath },
        });
      } else {
        next();
      }
    }
  } else {
    next();
  }
});*/





export default router
