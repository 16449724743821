<template>
    <section class="auth">
      <div class="brand"><img src="~@/assets/logo-ld.svg"></div>
      <div class="user">

         <form>
           <input type="number" placeholder="usuario" ref="inputUser" v-model="inputUser" />
           <input type="password" placeholder="contraseña" ref="inputPass" v-model="inputPass"/>
           <div class="alert" ref="alert">¡ERROR! - <span ref="errormsg">Usuario y/o contraseña invalidos</span></div>
           <div class="actions">
              <a href="javascript:void(0)" class="btn accept large" @click="onClickAuth">INGRESAR</a>
            </div>
         </form>
      </div> 
    </section>
</template>


<style scoped>
section { max-width:768px; margin:auto; padding-bottom:200px; }
.brand { width:80%; margin:auto;}
form { text-align:center; width:80%; margin:auto; margin-top:50px; }
form h1 { font-weight: 500; font-weight: 400 ; font-size:20px;}
form input { background:#eeeeee; display:block; border:#e4e4e4 solid 1px;  border-radius: 3px; padding:12px 15px; display: block; margin-bottom:20px; width: 100%; } 
.actions { margin-top:30px;}
a.btn {background: linear-gradient(180deg, #00A8F4 0%, #006B9B 100%); border-radius:100px; display:inline-block;  color:#fff; text-align:center; padding:10px 20px; font-size:11px; font-weight:bold; letter-spacing:1px; min-width:120px; }
a.btn.large { display:inline-block; min-width:200px; }
 form .alert { background-color:#e74c3c; font-size:14px; padding:5px; color:#fff; font-weight:bold; display:none; border-radius:3px;opacity:0; transition: opacity 0.5s ease; margin-top:5px;
 }
 form .alert.on { display:inline-block; opacity:1;  }
form .alert span { font-weight:500;}
</style>


<script>
import axios from 'axios'

export default {
  name: 'AuthView',
  components: {},
  data(){
    return{
      inputUser: '',
      inputPass: '',
      results:[],

    };
  },

  methods : {
  
    onClickAuth : function(){
      this.$refs.alert.classList.remove("on");
    axios({
      method: 'post',
      url: '/api/GetUser',
      headers: { 'content-type': 'application/x-www-form-urlencoded',  },
      data: { Usuario: this.inputUser, Pass: this.inputPass },
    }).then((response) => {   
      this.results = response.data.Usuario;
      if(this.results!==undefined){

        localStorage.setItem("userToken",JSON.stringify(this.results[0]));
        this.$router.push({ path: '/' });
        this.$root.setLogged(true,this.results[0]);
      }else{
        this.$refs.alert.classList.add("on");
        localStorage.removeItem("userToken");
      }
      /*if(!this.results.hasOwnProperty('Usuario')){ 
        alert("usuario no existe");
        return false;
      }
      if(this.results.hasOwnProperty('Usuario') ){
        
      }*/
  
    }).catch(function(){ });
  }
 
},

}
</script>