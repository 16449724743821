<template>
  <HeaderNav title="AYUDA" />
  <div class="about">
    <section class="ayuda">
    <div class="wrapper">
      <div class="intro">
        <h2 class="title left">Video tutoriales</h2>
        <p class="left">A través de los videos puedes aprender a utilizar las funciones de la aplicación.</p>
      </div>
      <div class="videos">
        <ul>
          <li><div class='embed-container'><iframe src='https://www.youtube.com/embed/rwEgeS88_fQ' frameborder='0' allowfullscreen></iframe></div><h3><a href="#">¿Cómo genero una cotización?</a></h3></li>
          <li><div class='embed-container'><iframe src='https://www.youtube.com/embed/BA9AGACnooE' frameborder='0' allowfullscreen></iframe></div><h3><a href="#">¿Cómo puedo actualizar una cotización?</a></h3></li>
          <li><div class='embed-container'><iframe src='https://www.youtube.com/embed/sIRe136Nk18' frameborder='0' allowfullscreen></iframe></div><h3><a href="#">¿Cómo ver mis cotizaciones?</a></h3></li>

        </ul>
      </div>
      </div>
    </section>
  </div>
</template>

<script>
import HeaderNav from '@/components/HeaderNav.vue'

export default {
  name: 'AyudaView',
  components: {
    HeaderNav,
  }
}
</script>

<style scoped>
section { max-width:768px; margin:auto; padding-bottom:60px; padding-top:20px; }
.about .videos ul li { margin-bottom:40px; box-shadow:0px 10px 20px rgba(0,0,0,.25); border-radius:6px; overflow:hidden;}
.about .videos ul li a.thumb img { width:100%;}
.about .videos ul li h3 { text-align:left; padding:20px; }
.about .videos ul li h3 a { color:#333;}
.embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; }
.embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }
</style>
