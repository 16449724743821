<template>
   <HeaderNav title="CONFIGURACIÓN" />
   <section>
   <div class="settings">
    <ul>
      <li>
        <h3>Notificaciones</h3>
        <p>Activa las notificaciones para recibir alertas de tus cotizaciones.</p>
        <a href="javascript:void(0)" class="switch" ref="btnNotification" @click="getNotificaciones()"><span></span></a>
      </li>
      <li>
        <h3>Instalación</h3>
        <p>Instala esta aplicación en tu dispositivo móvil para una mejor experiencia.</p>
        <a href="javascript:void(0)" class="btn" @click="InstallCallBack">Instalar</a>
      </li>
      <li>
        <h3>Cerrar Sesión</h3>
        <p>Haz click para cerrar la sesión en este dispositivo.</p>
        <a href="javascript:void(0)" class="btn" @click="logout()">Salir</a>
      </li>
    </ul>
   </div>
   </section>

   

</template>


<script>
import HeaderNav from '@/components/HeaderNav.vue'


export default {
  data(){
    return{
      notificaciones: 0,
      instalacion: 0,
    };
  },
  name: 'ConfiguracionView',
  components: {
    HeaderNav,
  },
  created(){
  },

  methods: {

    logout() {

        localStorage.removeItem("userToken");
        this.$router.push({ path: '/login' });
        this.$root.setLogged(false,null);
    },
    InstallCallBack() {
      console.log(this.$refs.app);
      
    this.$refs.App.deferredPrompt.prompt()
    // Wait for the user to respond to the prompt
    this.deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        // Call another function?
      }
      this.deferredPrompt = null
    })
  },

  getNotificaciones(){
    //console.log( this.$OneSignal);
    //this.$OneSignal.showNativePrompt();
    this.$OneSignal.off(function(a,b){ 
      console.log(a);
      console.log(b);

    });

    this.$OneSignal.setSubscription(false);
   
  }


  },
  mounted() {
    let _self = this;
    //this.captureEvent();

    this.$OneSignal.getSubscription(function (e){
      console.log(e);
    });

    this.$OneSignal.getNotificationPermission(function(r){ 
      if(r=="granted"){ 
        _self.$refs.btnNotification.classList.add("on");
      }
    });
  },
  updated(){

  },
}

</script>

<style scoped>
.profile { padding-top:30px;}
.settings ul { margin-bottom:80px;}
.settings ul li { border-bottom:#e4e4e4 solid 2px; text-align: left; padding:20px; padding-right:160px; padding-top:40px; padding-bottom:40px; position: relative;  }

.settings ul li a.btn {background: linear-gradient(180deg, #00A8F4 0%, #006B9B 100%); border-radius:100px; display:inline-block;  color:#fff; text-align:center; padding:5px 10px; font-size:12px; font-weight:bold; letter-spacing:1px; position: absolute; right:20px; top:46px; min-width:70px; }
.settings ul li a.switch { background-color: #ccc; width:50px; height:25px; display: inline-block; position: absolute; right:20px; top:45px; border-radius: 100px; }
.settings ul li a.switch span { background-color:#fff; width:20px; height:20px; border-radius:100px; display:inline-block; position:absolute; left:4px; top:2px;}
.settings ul li a.switch.on span { background-color:#fff; right:4px;  left:auto; box-shadow:0px 0px 5px rgba(0,0,0,.05);}
.settings ul li a.switch.on { background-color:#1ac445; }

.settings ul li h3 { font-size:16px; color:#000; font-weight: bold; margin:0;}
.settings ul li p { font-size:14px; color:#595959; font-weight: 300; margin:0; }
</style>
