<template>
  <header>
  <img src="~@/assets/brand.svg" ref="logo">

  <h1>{{title}}</h1>
  <ul>
    <li><router-link to="/cotizacion"><i data-feather="shopping-cart"></i><span class="qty" v-if="cartItems>0">{{ cartItems }}</span></router-link></li>
    <li><router-link to="/"><i data-feather="bell"></i><span class="qty orange" v-if="this.cotListas>0">{{ cotListas }}</span></router-link></li>
    <li><router-link to="/configuracion"><i data-feather="settings"></i></router-link></li>
    <!-- La lógica sería hsata que vez una cotización la campanita se borra o poner una float window aqui y marcar como leido.-->
  </ul>
  </header>
</template>

<script>
import feather from 'feather-icons'
import axios from 'axios'

export default {
  name: 'HeaderNav',
  props: { title: String },
  data(){
    return{
      cartItems: 0,
      cotListas: 0,
    };
  },
  methods : {
    setLoading(flag){
      if(flag)
      this.$refs.logo.classList.add("loading");
      else
      this.$refs.logo.classList.remove("loading");
    },
    incrementCount() {
        this.cartItems = this.$parent.cart.length;
      },
      getListas(){
      axios({
        method: 'post',
        url: '/api/GetCotizacion',
        headers: { 
          'content-type': 'application/x-www-form-urlencoded',
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': '0',
        },
        data: { usuario: this.$root.user.Usuario, Qty: 1000,UseDate: 0, DateIni: '',DateFin: '', notificar:1 }
      }).then((response) => {
        this.cotListas = response.data.Encabezado.length;
      });
    },

    verifyUser(){
      axios({
        method: 'post',
        url: '/api/GetUserActive',
        headers: { 
          'content-type': 'application/x-www-form-urlencoded',
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': '0',
        },
        data: { usuario: this.$root.user.Usuario }
      }).then((response) => {
        if(response.data.UsuarioActivo[0].Column1==0){
          localStorage.removeItem("userToken");
          this.$router.push({ path: '/login' });
          this.$root.setLogged(false,null);
        }
      });
    },

  },

  mounted() {
      
    feather.replace();
    //this.getListas();
    //this.verifyUser();
    
     
    
  }
}
</script>

<style>
@import url(~@/assets/css/loading.css);
</style>
<style scoped>
header {
  position:fixed; top: 0; height: 50px; background-color:#fff; width:100%; left:0; right:0; box-shadow:0px 0px 10px rgba(0,0,0,.35); z-index:1000; }
header h1 { font-size:12px; font-weight:bold; padding:17px; color:#5B5B5B; letter-spacing:.05em;}
header ul { position:absolute; top:14px; right:0px;}
header ul li { display:inline-block; margin-right:20px;}
header ul li a {color:#5B5B5B; position:relative; display:block;}
header ul li a .qty { background-color:red; width:16px; height:16px; position:absolute; top:-10px; border-radius:100px; color:#fff; font-size:11px; font-weight:500; line-height:17px;}
header ul li a .qty.orange { background-color: #ff946f; left:17px; }
header ul li svg { width:22px; height:22px;}
header img { max-width:40px; position:absolute; left:10px; top:5px;}


</style>
