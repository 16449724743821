<template>
   <HeaderNav title="HISTORIAL" ref="HeaderNav" />

   <section class="historial">
     <div class="modal off" ref="modal">

      <div class="window solicitar" ref="window_solicitar">
        <div class="info">
          <form  autocomplete="off">
        <h2>Solicitud de cátalogo</h2>
        <br>
        <div class="row">
          <div class="col">
            <label>Cátalogo</label><input type="text"  v-model="inputSolCat" ref="inputSolCat"/>
          </div>
          <div class="col">
            <label>Cantidad</label><input type="number" pattern="[0-9\/]*" v-model="inputSolCant" ref="inputSolCant"/>
          </div>
        </div>

        <div class="row">

          <div class="col">
            <label>Nivel de Precio</label>
            <select v-model="inputSolLista" ref="inputSolLista">
               <option value="" selected="selected"></option>
               <option value="0" v-if="this.$root.user.UsaPB==1">Precio Base</option>
               <option value="1" v-if="this.$root.user.UsaP1==1">Precio 1</option>
               <option value="2" v-if="this.$root.user.UsaP2==1">Precio 2</option>
               <option value="3" v-if="this.$root.user.UsaP3==1">Precio 3</option>
               <option value="4" v-if="this.$root.user.UsaP4==1">Precio 4</option>
               <option value="5" v-if="this.$root.user.UsaP5==1">Precio 5</option>
              </select>
          </div>
          <div class="col">
            <label>Marca</label>
            <select v-model="inputSolMarca" ref="inputSolMarca">
              <option v-for="(item, i) in marcas" :key="'item' + i">{{item.MARCA}}</option>
            </select>
          </div>
        </div>

        
    </form>
    <div class="actions">
          <div class="row">
            <div class="col">
              <a href="javascript:void(0)" class="btn cancel inline" @click="cancelRequest()">REGRESAR</a>
            </div>
            <div class="col right"><a href="javascript:void(0)" class="btn inline" @click="onProductRequest">ACEPTAR</a></div>
          </div>
         
          
        </div>
        </div>
     
    </div>


       <div class="window detalle" ref="window_detalle">
         <div class="info">
         <form>
       <h2 class="title left">Cotización #{{Folio}}</h2>

       <div class="row">
         <div class="col">
           <label>TIPO</label>
           <select v-model="tipoSelect">
             <option>Normal</option>
             <option>Urgente</option>
             <option>Compra</option>
           </select>
         </div>
         <div class="col">
           <label>PROYECTO</label><input type="text" v-model="proyectoInput" ref="proyectoInput"/>
         </div>
       </div>
         <div class="row">
           <div class="col"><label>CLIENTE</label>
            <input type="search" v-model="clienteInput"  @input='evt=>clienteInput=evt.target.value' @keyup="initClientSearch(this)" @click="onClientSearchClick(this)"  ref="clienteInput" class="zoom" />
            <div class="clientResult">
              <ul>
                <li v-for="(item, i) in clientResult" :key="'item' + i"><a href="javascript:void(0)" @click="onClickLoad(item)">{{item.NombreCliente}} ({{item.ContactoCliente}}) <br><span>{{item.email}}</span></a></li>
              </ul>
            </div>
          </div>
           <div class="col"><label>CONTACTO (NOMBRE)</label><input type="text" v-model="contactoInput" ref="contactoInput"/></div>
         </div>
         <div class="row">
           <div class="col"><label>TELÉFONO</label><input type="text" v-model="telefonoInput" ref="telefonoInput"></div>
           <div class="col"><label>E-MAIL (NOMBRE)</label><input type="text" v-model="correoInput" ref="correoInput"/></div>
         </div>

         <div class="row">
           <div class="col"><label>MONEDA</label>
             <select v-model="monedaSelect" disabled>
               <option>MXN</option>
               <option>USD</option>
             </select>
           </div>
             <div class="col"><label>CONDICIONES DE PAGO</label><input type="text" v-model="condicionesInput" ref="condicionesInput"/></div>

         </div>

         <div class="row">
          <div class="col">
            <label>TOTAL DE PARTIDAS</label>
            <h2 class="total">{{Detalle.length}}</h2>>
          </div>
          <div class="col">
            <label>TOTAL CON IVA</label>
            <h2 class="total">{{total}} <sup>{{monedaSelect}}</sup></h2>>
          </div>

        </div>
       </form>
       <div class="actions">
        <div class="row">
          <div class="col"><a href="javascript:void(0)" class="btn cancel large" @click="cancel(true)">CANCELAR</a></div>
          <div class="col right" align="right"><a href="javascript:void(0)" class="btn accept large" @click="onClickUpdate">GUARDAR</a></div>
        </div>
      </div>

     </div>


     <div class="search">
    <div class="inputWrapper">
      <input type="search" placeholder="Búsqueda por catálogo" v-model="searchInput" ref="searchInput"  @input='evt=>searchInput=evt.target.value'  @keyup="initSearch(this)" @click="onSearchClick(this)"  autocapitalize="off"/>
    </div>

    <div class="results">
      <div class="notfound" ref="notfound">
        <p align="center">No se encontraron resultados. </p>
        <a href="javascript:void(0)" class="btn small" @click="openRequest()">SOLICITAR CÁTALOGO</a>
      </div>
     
      <ul>
        <li v-for="(item, i) in arrProducts" :key="'item' + i"><a href="javascript:void(0)" @click="onClickAdd(item)"><h3>{{ item.CATALOGO }}</h3><h4>{{ item.MARCA }}</h4><p>{{ item.DESCRIPCION }}</p></a></li>
      </ul>
    </div>
  </div>

     <div class="products">




     <ul>
       <li v-for="(item, i) in Detalle" :key="'item' + i" v-bind:class="{ expired: item.EXPIRADO, requested : (item.ESNUEVO==1) }" @click="onClickUpdateItem($event,item)">
          <a href="javascript:void(0)" @click="onClickRem(i,$event)" class="btn remove"><span><i data-feather="trash"></i></span></a>
          <h3>{{ item.Catalogo }}</h3>
          <div class="detail"> <h4> Marca: <span>{{ item.Marca }}</span></h4><h4> Tiempo Entrega:<span>{{ item.tiempoEntregaF }}</span></h4></div>
          <div class="detail"> <h4> Precio: <span>{{ formatCurrency(item.PrecioUnitario) }}</span></h4><h4> Cantidad: <span>{{ item.Cantidad }}</span></h4><h4> Clave: <span>{{ item.Clave }}</span></h4></div>
          <div class="update" v-bind:class="{ on: item.isCurrAdded}">
          <form>
          <div class="row">
            <div class="col">
              <label>PRECIO</label>
              <div class="selector" v-if="(item.ESNUEVO!=1)">
                <select @change="onChangeItem(i,'precio',$event)" data-moneda="MXN" :class="this.monedaSelect=='MXN' ? '' : 'hidden'" :load="log(this.DetallePreciosAct[i],i)">
                  <option :value="item" data-lista="-1" :selected="item.Lista==''">(Seleccionar)</option>
                  <option v-if="this.$root.user.UsaPB==1" :value="this.DetallePreciosAct[i].MXPRECIO0" data-lista="0" :selected="formatCurrency(item.PrecioUnitario)==formatCurrency(this.DetallePreciosAct[i].MXPRECIO0)">Precio Base - {{ formatCurrency(this.DetallePreciosAct[i].MXPRECIO0) }} MXN</option>
                  <option v-if="this.$root.user.UsaP1==1" :value="this.DetallePreciosAct[i].MXPRECIO1" data-lista="1" :selected="formatCurrency(item.PrecioUnitario)==formatCurrency(this.DetallePreciosAct[i].MXPRECIO1)">Precio 1 - {{ formatCurrency(this.DetallePreciosAct[i].MXPRECIO1) }} MXN</option>
                  <option v-if="this.$root.user.UsaP2==1" :value="this.DetallePreciosAct[i].MXPRECIO2" data-lista="2" :selected="formatCurrency(item.PrecioUnitario)==formatCurrency(this.DetallePreciosAct[i].MXPRECIO2)">Precio 2 - {{ formatCurrency(this.DetallePreciosAct[i].MXPRECIO2) }} MXN</option>
                  <option v-if="this.$root.user.UsaP3==1" :value="this.DetallePreciosAct[i].MXPRECIO3" data-lista="3" :selected="formatCurrency(item.PrecioUnitario)==formatCurrency(this.DetallePreciosAct[i].MXPRECIO3)">Precio 3 - {{ formatCurrency(this.DetallePreciosAct[i].MXPRECIO3) }} MXN</option>
                  <option v-if="this.$root.user.UsaP4==1" :value="this.DetallePreciosAct[i].MXPRECIO4" data-lista="4" :selected="formatCurrency(item.PrecioUnitario)==formatCurrency(this.DetallePreciosAct[i].MXPRECIO4)">Precio 4 - {{ formatCurrency(this.DetallePreciosAct[i].MXPRECIO4) }} MXN</option>
                  <option v-if="this.$root.user.UsaP5==1" :value="this.DetallePreciosAct[i].MXPRECIO5" data-lista="5" :selected="formatCurrency(item.PrecioUnitario)==formatCurrency(this.DetallePreciosAct[i].MXPRECIO5)">Precio 5 - {{ formatCurrency(this.DetallePreciosAct[i].MXPRECIO5) }} MXN</option>

                </select>
              </div>

            </div>
            <div class="col">
              <label>CANTIDAD</label>
              <input type="number" d
              class="small" min="1" pattern="[0-9]*" @change="onChangeItem(i,'cantidad',$event)" @keyup="onChangeItem(i,'cantidad',$event)" :value="item.Cantidad"/>
            </div>
          </div>
          <div class="row mbzero">
            <div class="col">
              <label>CLAVE</label>
              <input type="text" placeholder="" class="small" @change="onChangeItem(i,'clave',$event)" @keyup="onChangeItem(i,'clave',$event)" :value="item.Clave" maxlength="5"/>
            </div>
            <div class="col">
    
            </div>
          </div>


        </form>
      </div>
      <p class="expired" v-if="(item.EXPIRADO==1 && item.ESNUEVO==0)"><i data-feather="alert-triangle"></i>Precio Expirado,  <span>pendiente de actualización</span></p>
          <p class="requested" v-if="item.ESNUEVO==1"><i data-feather="arrow-right-circle"></i>Cátalogo Solicitado</p>
        </li>
     </ul>
     <center><a href="javascript:void(0)" class="btn accept large" @click="onClickSend">ENVIAR COTIZACIÓN</a></center>
     <br><br><br>
     </div>


     </div>

     </div>
     <div class="searchCot">
       <input type="text" placeholder="Búsqueda por cliente, proyecto" v-model="searchCotInput"  @keyup="onSearch(this)" />
     </div>

     <div class="filter">
          <a href="javascript:void(0)" class="tab null active" @click="setFilter($event)" data-filter=""><i data-feather="archive"></i>Todas</a>
          <a href="javascript:void(0)" class="tab" @click="setFilter($event)" data-filter="Generada"><i data-feather="check"></i>Generadas</a>
          <a href="javascript:void(0)" class="tab" @click="setFilter($event)" data-filter="Solicitada"><i data-feather="clock"></i> Solicitadas</a>
        </div>

     <div class="results lst">
       <ul>
         <li v-for="(item, i) in results" :key="'item' + i">
          <a href="javascript:void(0)" @click="onClickCot(i)">
            <span class="btn"><i data-feather="chevron-right"></i></span>
            <h3>COT #{{ item.Folio }} - {{ item.Proyecto }}</h3>
            <h4>{{ item.NombreCliente }}</h4>
            <h5>{{ formatCurrency(item.Total) }} {{ item.TipoMoneda }}</h5>
            <h6><i data-feather="calendar"></i> {{ formatDate(item.FechaCotizacion) }}</h6>

            <span class="tag generada" v-if="item.ESTATUS1=='Generada'"><i data-feather="check"></i> Generada</span>
            <span class="tag solicitada" v-if="item.ESTATUS1=='Solicitada'"><i data-feather="clock"></i> Solicitada</span>
            <span :class="item.tipo=='Urgente' ? 'tag urgente' : 'tag'" v-if="item.tipo=='Urgente'">
              <i data-feather="alert-circle"></i>
              Urgente
            </span>
            <span :class="item.tipo=='Compra' ? 'tag compra' : 'tag'" v-if="item.tipo=='Compra'">
              <i data-feather="dollar-sign"></i>
              Compra
            </span>
            <span :class="item.Notificar==1 ? 'tag notificar' : 'tag'" v-if="item.Notificar==1">
                <i data-feather="bell"></i>
                Lista
              </span>

          </a>
        </li>
       </ul>

     </div>

   </section>

  <div class="editor">
   <form>
     <div class="row">
       <div class="col">
         <label>PRECIO</label>
         <select>
           <option></option>
         </select>
       </div>
       <div class="col">
       </div>
     </div>
     <div class="row">
       <div class="col">
         <label>CANTIDAD</label>
         <input type="number" value="1" class="small" min="0" pattern="[0-9]*"/>
       </div>
       <div class="col">
         <label>CLAVE</label>
         <input type="text" placeholder="" class="small"/>
       </div>
     </div>
   </form>
</div>

</template>


<script>
import feather from 'feather-icons'
import HeaderNav from '@/components/HeaderNav.vue'
import axios from 'axios'
import {_} from 'vue-underscore';

export default {
  data(){
    return{
      inputSolCant: '',
      inputSolCat: '',
      inputSolLista: '',
      inputSolMarca:'',
      arrProducts: [],
      searchCotInput: '',
      searchInput: '',
      cotizaciones: [],
      results: [],
      searchResults: [],
      Detalle: [],
      DetallePreciosAct: [],
      display:'none',
      tipoSelect: '',
      clienteInput: '',
      contactoInput: '',
      correoInput: '',
      telefonoInput: '',
      proyectoInput: '',
      condicionesInput: '',
      monedaSelect: '',
      total:'',
      Folio:'',
      cart:[],
      marcas:[],
      clientResult : [],
    };
  },
  name: 'HistorialView',
  components: {
    HeaderNav,
  },
  created(){
    window.addEventListener('popstate', this.popstateEventAction );
  },

  methods: {


    log(item,idx) {
      console.log(item.CATALOGO);
      console.log("index: "+idx);
    },


    showModal : function(window){
    this.$refs.modal.classList.remove("off");
    this.$refs.notfound.classList.remove("on");
    
    this.$refs.window_detalle.classList.remove("on");
    this.$refs.window_solicitar.classList.remove("on");
    
    if(window=="solicitar"){
      this.$refs.window_solicitar.classList.add("on");
    }

    if(window=="detalle"){
      this.$refs.window_detalle.classList.add("on");
    }
    
  },

  onProductRequest : function(){
    
    //let exists = _.findWhere(this.Detalle, { Catalogo: this.inputSolCat });
    //if(exists){ alert("El cátalogo ya se encuentra solicitado en su lista"); return; }
    let el = { IDPRODUCTO: '',}
    this.Detalle.push(el);
    this.Detalle[(this.Detalle.length-1)] = {
      TiempoEntrega: '',
      Cantidad: this.inputSolCant,
      Clave:'',
      Lista:this.inputSolLista,
      ESNUEVO:1,
      EXPIRADO: 0,
      Importe: 0,
      Marca: this.inputSolMarca,
      Catalogo: this.inputSolCat,
      PrecioUnitario: 0,
      Descripcion: "",
      IdProducto: 0,
    }

    this.DetallePreciosAct[(this.Detalle.length-1)] = {
      MXPRECIO0: 0,
      MXPRECIO1: 0,
      MXPRECIO2: 0,
      MXPRECIO3: 0,
      MXPRECIO4: 0,
      MXPRECIO5: 0
    }

    this.results = '';
    this.searchInput = '';
    this.getTotal();
    this.cancelRequest();
    console.log(this.Detalle);
  },


  cancelRequest(){
      this.$refs.inputSolCant.classList.remove("error"); 
      this.$refs.inputSolCat.classList.remove("error"); 
      this.$refs.inputSolLista.classList.remove("error"); 
      this.$refs.inputSolMarca.classList.remove("error");

      this.inputSolCat = '';
      this.inputSolCant = '';
      this.inputSolLista = '';
      this.inputSolMarca = '';
      this.$refs.notfound.classList.remove("on");
      this.$refs.window_solicitar.classList.remove("on");
      this.$refs.window_detalle.classList.add("on");
      this.searchInput = '';
  },

  closeModal : function(){
    this.$refs.modal.classList.add("off");
  },

    onClickUpdateItem(evt){
      let li = evt.currentTarget.querySelector('.update');
      li.classList.add("on");
    },


    initClientSearch(){
      this.clientResult = '';     
      if(this.clienteInput.length<=0){  this.clientResult = ''; }
      if(this.clienteInput.length<=2){ return false;}
      setTimeout(this.onSearchClient,500);
  },

  getMarcas : function(){
    axios({
      method: 'post',
      url: '/api/GetMarca',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: {  },
    }).then((response) => {
      this.marcas = response.data.Marca;
    });
  },

  async onSearchClient(){
    this.$refs.HeaderNav.setLoading(true);
   
    await axios({
      method: 'post',
      url: '/api/GetClient',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: { Cliente: this.clienteInput },
    }).then((response) => {
      this.clientResult = '';
      this.clientResult = response.data.Cliente;
      this.$refs.HeaderNav.setLoading(false);

    });
  },

  onClientSearchClick(){
    this.clientResult = '';     
    this.$refs.HeaderNav.setLoading(false);
    this.clienteInput = '';
  },

  onClickLoad: function(item){
    this.clientResult = [];
    this.clienteInput = item.NombreCliente;
    this.contactoInput = item.ContactoCliente;
    this.telefonoInput = item.TelCliente;
    this.correoInput = item.email;
    this.condicionesInput = item.CondicionesPago;
  },

    onChangeItem(idx,target,event){
      if(target=="precio"){
        this.Detalle[idx].PrecioUnitario = event.target.value;
        this.Detalle[idx].Lista = parseInt(event.target.options[event.target.selectedIndex].getAttribute('data-lista'));
      }else if(target=="cantidad"){
        this.Detalle[idx].Cantidad = event.target.value;
      }else if(target=="clave"){
        this.Detalle[idx].Clave = event.target.value;
      }
      this.getTotal();
    },


    popstateEventAction() {
       //this.removePopstateEventAction();
        return false;



      },
      removePopstateEventAction() {
        window.removeEventListener('popstate', this.popstateEventAction);
      },

    formatCurrency(val){
      let formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD'});
      return formatter.format(val);
    },


    onClickRem : function(idx,$evt){
      $evt.stopPropagation();
      if(confirm("Confirmar que desea eliminar "+this.Detalle[idx].Catalogo)){
        this.Detalle.splice(idx, 1);
        this.DetallePreciosAct.splice(idx, 1);
      }
      this.getTotal();
    },

    getTotal : function(){
      let items = this.Detalle;
      let subtotal = { MXN:0.0 }
      for(let i=0;i<items.length;i++){
        subtotal.MXN += items[i].PrecioUnitario*parseInt(items[i].Cantidad);
      }
      if(isNaN(subtotal.MXN)){ subtotal.MXN = 0.0; }
      this.total = this.formatCurrency(subtotal.MXN);
      return this.total;
    },

    async onClickCot(idx){
      let updatedivs = document.querySelectorAll("div.update");
      updatedivs.forEach((element) => { element.classList.remove('on'); });
      
      await axios({
        method: 'post',
        url: '/api/GetCotiDetalle',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        data: { cotizacion: this.results[idx].Folio }
      }).then((response) => {
        //this.results = response.data.Encabezado;
        this.GetPreciosActDet(this.results[idx].Folio,response.data.Detalle);
        
        this.tipoSelect = this.results[idx].tipo;
        this.clienteInput = this.results[idx].NombreCliente;
        this.contactoInput = this.results[idx].ContactoCliente;
        this.correoInput = this.results[idx].email;
        this.telefonoInput = this.results[idx].TelCliente;
        this.proyectoInput = this.results[idx].Proyecto;
        this.condicionesInput = this.results[idx].CondicionesPago;
        this.monedaSelect = this.results[idx].TipoMoneda;
        this.Folio = this.results[idx].Folio;
        this.total = this.formatCurrency(this.results[idx].Total);
        
      });

    },

    async GetPreciosActDet(idx,data){
      await axios({
        method: 'post',
        url: '/api/GetPreciosActDet',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        data: { cotizacion: idx }
      }).then((response) => {
        this.DetallePreciosAct = response.data.Detalle;
        console.log("----");
        console.log(this.DetallePreciosAct);
        console.log("----");
        console.log("pase X");
        this.Detalle = data;
        console.log("pase X");
        this.showModal("detalle");

        
        
        
        
      });

    },

    onSearch(){

      var str = this.searchCotInput;
      var data = this.cotizaciones;

      if(str == ""){
        this.results = this.cotizaciones;
        document.querySelector(".tab.active").classList.remove("active");
        document.querySelector(".tab.null").classList.add("active");
        return false;
      }

      let searchResults = _.filter(data, function(item) {
        console.log(item);
        var folio = str.match(/(\d+)/);
        if(folio){
          return (item.Folio==folio[0] || item.NombreCliente.toLowerCase().includes(str)==true || item.Proyecto.toLowerCase().includes(str)==true)
        }else{
          return (item.NombreCliente.toLowerCase().includes(str)==true || item.Proyecto.toLowerCase().includes(str)==true)
        }
      });
      if(searchResults.length>0){ this.results = searchResults;  }
      document.querySelector(".tab.active").classList.remove("active");
        document.querySelector(".tab.null").classList.add("active");

    },

    onUpdate(evt){
      let el = evt.target.parentNode;
      let ed = document.querySelector(".editor");
      ed.style.display = 'block';
      console.log(ed);
      el.append(ed);

    },


    /* GET COTIZACIONES  */
    GetCotizacion(){
      let d = new Date();
      axios({
        method: 'post',
        url: '/api/GetCotizacion?d='+d.getTime(),
        headers: { 
          'content-type': 'application/x-www-form-urlencoded',
          'Cache-Control': 'no-cache',
          'Pragma': 'no-cache',
          'Expires': '0', 
        },
        data: { usuario: this.$root.user.Usuario, Qty: 100,UseDate: 0, DateIni: '',DateFin: '', notificar:0, dt: d.getTime() }
      }).then((response) => {
        this.results = response.data.Encabezado;
        this.cotizaciones = response.data.Encabezado;
        this.$refs.HeaderNav.setLoading(false);
      });
    },

    /* FORMAT DATE */
    formatDate(dateString){
      const date = new Date(dateString);
      return new Intl.DateTimeFormat('es-MX', {dateStyle: 'long'}).format(date);
    },

    onClickSend: function(){
    
    // Cotización General
    let formData = {
      idcotiza: this.Folio,
      correo: this.correoInput,
    }


    axios({
      method: 'post',
      url: '/api/GetPdf',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: formData,
    }).then(() => {
       alert("Su cotización ha sido enviada");
       this.closeModal();
    });
  },


    onClickUpdate(){
      let _self = this;
    // Cotización General
    let formData = {
      cotizacion: this.Folio,
      tipo: this.tipoSelect,
      proyecto: this.proyectoInput,
      cliente: this.clienteInput,
      contacto: this.contactoInput,
      telefono: this.telefonoInput,
      correo: this.correoInput,
      condiciones: this.condicionesInput,
      moneda: this.monedaSelect,
      total: this.total,
      usuario: this.$root.user.Usuario,
    }

    //validate
    let flag = true;
    this.$refs.proyectoInput.classList.remove("error");
    this.$refs.clienteInput.classList.remove("error");
    this.$refs.contactoInput.classList.remove("error");
    this.$refs.telefonoInput.classList.remove("error");
    this.$refs.correoInput.classList.remove("error");
    this.$refs.condicionesInput.classList.remove("error");

    if(formData.proyecto==""){  this.$refs.proyectoInput.classList.add("error"); flag = false; }
    if(formData.cliente==""){  this.$refs.clienteInput.classList.add("error"); flag = false; }
    if(formData.contacto==""){  this.$refs.contactoInput.classList.add("error"); flag = false; }
    if(formData.telefono==""){  this.$refs.telefonoInput.classList.add("error"); flag = false; }
    if(formData.correo==""){  this.$refs.correoInput.classList.add("error"); flag = false; }
    if(formData.condiciones==""){  this.$refs.condicionesInput.classList.add("error"); flag = false; }
    if(!flag){ return false;}

    if(!confirm("¿Desea actualizar la cotización?")){ return false;  }

    axios({
      method: 'post',
      url: '/api/SetActuCotiza',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: formData,
    }).then(() => {
      console.log("Actualiza la cotización");
      //elimina detalle y ingresa nuevo detalle


      axios({
        method: 'post',
        url: '/api/SetBorraDetalle',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        data: { cotiza: this.Folio }
      }).then(() => {
        for(var i=0;i<this.Detalle.length;i++){
        let detalle = {
          cotiza: this.Folio,
          catalogo: this.Detalle[i].Catalogo,
          descripcion: this.Detalle[i].Descripcion,
          cant: this.Detalle[i].Cantidad,
          marca: this.Detalle[i].Marca,
          precioUnitario: this.Detalle[i].PrecioUnitario,
          tiempoEntrega: this.Detalle[i].TiempoEntrega,
          idproducto: this.Detalle[i].IdProducto,
          lista: this.Detalle[i].Lista,
          clave: this.Detalle[i].Clave.toString(),
          esNuevo: this.Detalle[i].ESNUEVO,
          expirado: this.Detalle[i].EXPIRADO,
          Partida: (i+1),
        }
        //if(detalle.expirado==1){ this.cotSolicitada = 1;}
        let insertLog = _self.SetCotiza(detalle);
        console.log(insertLog);
      }

       this.results = [];
       this.DetallePreciosAct = [];
       this.Detalle = [];
       setTimeout(function(){ _self.GetCotizacion(); },1000);
       this.closeModal();
        
      });
      
   

     
      
    });
  },


  async SetCotiza(detalle) {
    let response = await axios({
      method: 'post',
      url: '/api/SetCotizaDet?q='+(Math.floor(Math.random() * 10000) + 1) / 1000,
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      data: detalle,
    });
    return response.data;
  },


  setFilter : function(event){
    event.preventDefault();
    this.searchCotInput ='';
    document.querySelector(".active").classList.remove("active");
    event.currentTarget.classList.add("active");
    let str = event.currentTarget.getAttribute('data-filter');
    if(str==""){
      this.results = this.cotizaciones;

    }else{
      let data = this.cotizaciones;
      this.results = _.where(data, { ESTATUS1: str });
    }



  },

  cancel : function(){

      this.$refs.proyectoInput.classList.remove("error");
      this.$refs.clienteInput.classList.remove("error");
      this.$refs.contactoInput.classList.remove("error");
      this.$refs.telefonoInput.classList.remove("error");
      this.$refs.correoInput.classList.remove("error");
      this.$refs.condicionesInput.classList.remove("error");

      this.tipoSelect = 'Normal';
      this.searchInput = '';
      this.searchCotInput = '';
      
      this.clienteInput = '';
      this.contactoInput = '';
      this.correoInput = '';
      this.telefonoInput = '';
      this.proyectoInput = '';
      this.condicionesInput = '';
      this.monedaSelect = 'MXN';
      this.total = 0;
      this.Detalle = [];
      this.closeModal();



  },


  onSearchClick(){
    this.arrProducts = '';
    this.searchInput = '';
    this.$refs.notfound.classList.remove("on");
  },

  initSearch(){
    this.arrProducts = '';
    setTimeout(this.onSearchProducts,500);
  },

  async onSearchProducts(){


      await axios({
        method: 'post',
        url: '/api/GetProduct',
        headers: { 'content-type': 'application/x-www-form-urlencoded',  },
        data: { Producto: this.searchInput.toUpperCase() },
      }).then((response) => {   
        this.arrProducts = '';
        this.arrProducts = response.data.Producto;
        if(this.arrProducts.length<=0){
          this.$refs.notfound.classList.add("on");
        }else{
          this.$refs.notfound.classList.remove("on");
        }
      }).catch(function(){ });
  },

  onClickAdd : function(el){
    //let exists = _.findWhere(this.Detalle, { IdProducto: el.IDPRODUCTO });
    //if(exists){ alert("Ya existe ese producto en su lista"); return; }
    this.Detalle.push(el);
    this.Detalle[(this.Detalle.length-1)] = {
      Cantidad: 1,
      Catalogo:el.CATALOGO,
      Descripcion:el.DESCRIPCION,
      Clave:'',
      Lista:'-1',
      Marca:el.MARCA,
      NumPartida:0,
      PrecioUnitario: 0,
      TiempoEntrega: el.tiempoEntregaF,
      IdProducto: el.IDPRODUCTO,
      EXPIRADO: el.EXPIRADO,
      ESNUEVO:0,
      isCurrAdded:1,

    };

    this.DetallePreciosAct[(this.Detalle.length-1)] = {
      MXPRECIO0: el.MXPRECIO0,
      MXPRECIO1: el.MXPRECIO1,
      MXPRECIO2: el.MXPRECIO2,
      MXPRECIO3: el.MXPRECIO3,
      MXPRECIO4: el.MXPRECIO4,
      MXPRECIO5: el.MXPRECIO5
    }

    this.results = '';
    this.searchInput = '';

    this.arrProducts = '';
    this.searchInput = '';
    this.$refs.notfound.classList.remove("on");

    this.getTotal();
  },

  openRequest(){
    this.inputSolCat = this.searchInput;
    this.showModal('solicitar');
  },


  },
  mounted() {
    this.$refs.HeaderNav.setLoading(true);
    this.GetCotizacion();
    this.getMarcas();

  },
  updated(){
    this.$nextTick(function () {
      feather.replace();
    });
  },
}

</script>

<style scoped>
* { box-sizing:border-box;}
a.btn {background: linear-gradient(180deg, #00A8F4 0%, #006B9B 100%); border-radius:100px; display:block;  color:#fff; text-align:center; padding:10px 20px; font-size:11px; font-weight:bold; letter-spacing:1px; }
a.btn.large { display:inline-block; min-width:100px; }
a.btn.cancel {background: linear-gradient(180deg, #FF7675 0%, #D22728 100%);  }
a.btn.inline { display:inline-block;}

.modal { position:fixed; top:0; left:0; right:0; bottom:0; background:rgba(0,0,0,.80); z-index:1000; overflow-y:scroll; }
.modal.off { display:none;}
.modal .window { max-width:768px; margin:auto;  background:#e4e4e4;padding-bottom:100px; width:95%; margin-top:30px; padding:15px; border-radius:6px; position:relative; display:none; }
.modal  .window.on { display:block;}


.modal a.btn.remove { width:28px; height:28px; background: linear-gradient(180deg, #FF7675 0%, #D22728 100%); border-radius:100px; display:block !important; min-width:auto; position:absolute; right:6px; bottom:6px; color:#fff; text-align:center; z-index:9; padding:0px; line-height:34px; }
.modal a.btn.remove span svg { width:16px; height:16px;}
.modal .info { border:#a4b0be solid 1px; background-color:#fff;border-radius:6px;}
.modal .info h2 { margin-bottom:20px; color:#333;}
.modal .info form { position: relative; padding:15px; padding-top:25px;  }

.modal .info form .clientResult { position:relative; z-index:1; }
.modal .info form .clientResult ul { font-size:14px; position: absolute; background-color:#fff; width:95%; box-shadow:0px 0px 5px rgba(0,0,0,.15); max-height: 170px; overflow-y: scroll;}
.modal .info form .clientResult ul li { border-bottom:#e4e4e4 solid 1px; padding:4px;}
.modal .info form .clientResult ul li a { color:#000; font-weight: bold; font-size: 12px; padding-left:10px; display:block;}
.modal .info form .clientResult ul li a span { color:#777; font-size: 11px; font-weight: 300;}
.modal .info form h2.title { position: absolute; top:-10px; background-color: #a4b0be; color:#fff; padding:3px 10px; border-radius:3px; font-size:13px;}
.modal .info form h2.total { font-size:16px; color:#666; margin-bottom:0px;}
.modal .info form h2.total sup { font-size:10px;}
.modal .info .row { font-size:0; margin-bottom:15px;}
.modal .info .row:last-child { margin-bottom:0px; }
.modal .info .row .col { width:50%; display:inline-block; vertical-align:top; text-align:left;}
.modal .info .row .col input { width:95%; height:30px; border:#a4b0be solid 1px;}
.modal .info .row .col input.error { border:#e74c3c solid 1px; background:url("~@/assets/warning.svg") right center no-repeat #fff; background-size:22px 22px; background-position: right 5px center; }
.modal .info .row .col h2 { font-size:18px;}
.modal .info .row .col label {color:#333; text-transform: uppercase; font-weight:bold; font-size:11px; display:block; text-align:left;}
.modal .info .row .col select { width:95%; height:30px; border:#a4b0be solid 1px;}
.modal .info .actions {  border-top:#a4b0be solid 1px; padding:10px 20px; background-color: #a4b0be; }
.modal .info .actions .row { margin-bottom: 0;}
.modal .info .actions .row .col.right { text-align:right;}
.modal .products ul { margin-top:15px; border-radius:6px; margin-bottom:50px; overflow:hidden;}

.modal .products ul li { border-bottom:#ccc solid 1px; text-align: left; background-color:#fff; padding:15px; position:relative;}
.modal .products ul li:last-child { border:none;}
.modal .products ul li a { padding:10px;  display:block; position:relative; color:#333;}
.modal .products ul li span.btn { width:32px; height:32px; background: linear-gradient(180deg, #00A8F4 0%, #006B9B 100%); border-radius:100px; display:block; position:absolute; right:5px; top:5px; color:#fff; text-align:center; line-height:45px; }
.modal .products ul li h3 { font-size:14px; color:#000; font-weight: bold;}
.modal .products ul li h4 { font-size:13px; color:#757379; font-weight: 500; display:inline-block; margin-right:20px; }
.modal .products ul li h4 span { color:#000;}
.modal .products ul li h5 { color:#00830D; text-transform:uppercase; font-size: 14px; }
.modal .products ul li h6 { color:#424242; margin-top:5px; font-weight:600;}
.modal .products span.pill { background-color:#e5e5e5; color:#333; border-radius:3px; padding:1px 6px; font-size:13px; font-weight: 500;}


.modal .products .row { margin-bottom:10px; }
.modal .products .row.mbzero { margin-bottom:0px;}
.modal .products .row .col { width:50%; display:inline-block; vertical-align:top; font-size:14px;}
.modal .products .row .col:first-child { width:70%;}
.modal .products .row .col:last-child { width:30%}
.modal .products .row .col.right { text-align:right;}

.modal .products .update { display:none;}
.modal .products .update.on { display:block;}
.modal .products .update form { margin-top:10px;}
.modal .products .update form .row .col:first-child  { padding-right:10px;}
.modal .products .update form .row .col:last-child  { padding-left:10px;}
.modal .products .update form label { display:block; font-weight:700; font-size:12px; }
.modal .products .update form input { border:#ccc solid 1px; width:100%; height:30px; }
.modal .products .update form input.small { max-width:80px;}
.modal .products .update form select { border:#ccc solid 1px; width:100%; height:30px;}

.modal .products ul li.expired { border:#F47C7C solid 1px; background-color:#FFF2F2; display:block;  }
.modal .products ul li.expired p.expired { background:#D22728; color:#fff; padding:4px 6px; display:inline-block;  border-radius:3px; font-weight:600; font-size:11px; text-transform: uppercase; vertical-align:middle; margin-bottom:0px;}
.modal .products ul li.expired p.expired svg { width:13px; height: 13px; vertical-align: top; margin-right:4px;}


.modal .products ul li.requested { border:#ffc048 solid 1px; background-color:#fbf7df; display:block; }
.modal .products ul li.requested p.requested { background:#ffa801; color:#fff; padding:4px 6px; display:inline-block;  border-radius:3px; font-weight:600; font-size:11px; text-transform: uppercase; vertical-align:middle; margin-bottom:0px; }
.modal .products ul li.requested p.requested svg { width:13px; height: 13px; vertical-align: top; margin-right:4px;}


.historial .searchCot { padding:15px 15px; position:relative; z-index:20; background: linear-gradient(180deg, #00A8F4 0%, #006B9B 100%); }
.historial .searchCot input { border:#ccc solid 1px; width:100%; height:35px; border-radius:6px; text-indent:10px; background:url("~@/assets/search.svg") right center no-repeat #fff; background-size:22px 22px; background-position: right 10px center; }
.filter a {  display:inline-block; width:33.33%; padding:15px 0px; border-bottom:#e4e4e4 solid 2px; color:#333; vertical-align: middle; font-size:14px; display:inline-block; position:relative; }
.filter a svg { width:16px; height:16px; vertical-align: top; margin-right:3px; margin-top:1px;}
.filter a.active { border-bottom:#1EB0ED solid 2px; font-weight: bold; background-color:#ececec;}
.filter a:first-child { border-right:#e4e4e4 solid 1px;}
.filter a:last-child { border-left:#e4e4e4 solid 1px;}


.products .results { padding:20px; padding-top:0px; padding-bottom:100px;}
.products .results .row { margin-bottom:20px;}
.products .results .row .col { width:50%; display:inline-block; vertical-align:middle; font-size:14px;}
.products .results .row .col.right { text-align:right;}
.products .results ul { margin-left:-20px; margin-right:-20px; }
.products .results ul li:first-child { border-top:none;}

.results.lst ul { padding-bottom:100px;}

.results ul li { border-top:#e4e4e4 solid 2px; text-align: left;  }
.results ul li:odd {}
.results ul li:even { background-color:#ccc;}
.results ul li a { padding:10px;  display:block; position:relative; padding-left:15px;}
.results ul li span.btn { width:32px; height:32px; display:block; position:absolute; right:5px; top:30px; color:#ccc; text-align:center; line-height:42px; }
.results ul li span.btn svg { width:24px; height:24px;}
.results ul li h3 { font-size:14px; color:#000; font-weight: bold;}
.results ul li h4 { font-size:12px; color:#595959; font-weight: 500; margin-bottom:5px; }
.results ul li h5 { color:#3f8936; text-transform:uppercase; margin-bottom:5px; }
.results ul li h6 { color:#424242; margin-top:5px; font-weight:300; font-size:12px; font-weight: 500; margin-bottom:5px;}
.results ul li h6 svg { width:16px; height: 16px; vertical-align: top; margin-top:0px; color:#999;}
.results ul li span { }
.results ul li span.tag { background-color:#e5e5e5; color:#fff; border-radius:3px; padding:3px 6px; font-size:11px; font-weight: 600;display:inline-block;  letter-spacing:.03em; margin-right:10px;}
.results ul li span.tag svg { width:14px; height: 14px; vertical-align: top; margin-top:0px; }
.results ul li span.tag.generada { background-color: #eefbf5; color:#69cfa1; border:#69cfa1 solid 1px;  }
.results ul li span.tag.solicitada { background-color: #faeeef; color:#ef737e; border:#ef737e solid 1px; }
.results ul li span.tag.urgente { background-color: #fff9ec; color:#ffa801; border:#ffa801 solid 1px; }
.results ul li span.tag.compra { background-color: #e9f9fc; color:#59cee6; border:#59cee6 solid 1px; }
.results ul li span.tag.notificar { background-color: #fff1ec; color:#ff946f; border:#ff946f solid 1px; }

.search { padding:15px 15px; position:relative; z-index:20; }
.search input { border:#ccc solid 1px; width:100%; height:35px; border-radius:6px; text-indent:10px; background:url("~@/assets/search.svg") right center no-repeat #fff; background-size:22px 22px; background-position: right 5px center; position:relative; }
.search input:focus { background:#fff;}
.search input::-webkit-search-cancel-button{
    -webkit-appearance: none;
    background:url("~@/assets/x.svg") right center no-repeat #fff;
    width:20px;
    height:20px;
}
.search .inputWrapper { position:relative; display:inline-block; width:100%; height:35px; border-radius:6px; }
.search .results { background-color:#fff;}
.search { position: relative;;}
.search .results  { background:#fff; max-height:360px; overflow-y:scroll; border-radius:6px; position: absolute; width:100%; box-shadow:0px 0px 10px rgba(0,0,0,0.25); }
.search .results .notfound { display:none; max-width:50%; margin:auto; padding-top:30px; padding-bottom:30px;}
.search .results .notfound p { margin-bottom:10px; display:block;}
.search .results .notfound.on { display:block;}
.search .results ul li { text-align: left; border-bottom:#ccc solid 1px; position:relative; overflow:hidden;  }
.search .results ul li a img { max-width:80px; position:absolute; left:5px; top:10px; border:#e4e4e4 solid 1px;}
.search .results ul li a { padding:6px;  display:block; position:relative; padding-left:15px;}
.search .results ul li span.btn { width:32px; height:32px; background: linear-gradient(180deg, #FF7675 0%, #D22728 100%); border-radius:100px; display:block; position:absolute; right:5px; top:5px; color:#fff; text-align:center; line-height:45px; }
.search .results ul li h3 { font-size:14px; color:#000; font-weight: bold;}
.search .results ul li h4 { font-size:13px; color:#757379; font-weight: 300; }
.search .results ul li p { color:#333; font-size:12px; margin-top:5px;}
.results .results ul li h6 { color:#666; margin-top:5px; font-weight:300;}


.editor  { display:none;}
.editor form { margin-top:45px; }
.editor form .row { font-size:0;}
.editor form .row .col { width:50%; display:inline-block; text-align:left; }
.editor form .row .col:first-child  { padding-right:10px;}
.editor form .row .col:last-child  { padding-left:10px;}
.editor form label { display:block; font-weight:700; font-size:12px; }
.editor form input { border:#ccc solid 1px; width:100%; height:30px; }
.editor form input.small { max-width:100px;}
.editor form select { border:#ccc solid 1px; width:100%; height:30px;}
</style>
